
import { Navigate, useParams } from 'react-router-dom';
import { User } from 'firebase/auth';
import React, { useState, useEffect, useMemo } from 'react';
import {MobileView, BrowserView} from 'react-device-detect';

import { Conversation, DocumentValue, LoomNodeValue, PromptTemplate, Transcription } from '../common/common_db';
import { ModelType } from '../common/llm_clients';
import {textFromTemplatedPrompt} from '../common/util';
import { callCompletions } from '../callables';
import { firebaseAuth, getCurrentUser } from '../firebase_config';
import { FireLoomStore } from '../db';
import PromptTemplateForm from '../components/PromptTemplateForm';
import LoadingSpinner from '../components/LoadingSpinner';
import EditableText from '../components/EditableText';
import TemplatesSidebar from '../components/TemplatesSidebar';
import Tooltip from '../components/Tooltip';
import './Loom.css';

type LoomEvents = {
  document: string;
  isOwner: boolean;
  incrementVersion: () => void;
  isGenerating: (nodeID: string) => boolean;
  generateChildren: (nodeID: string) => Promise<string[]>;
  getDocument(docID: string): Promise<DocumentValue | null>;
  updateDocument(docID: string, docVal: DocumentValue): Promise<void>;
  getNode(nodeID: string): Promise<LoomNodeValue | null>;
  getChildren(nodeID: string): Promise<string[]>;
  invalidateChildCache(nodeID: string): void;
  createNode(docID: string, parentID: string | null, text: string) : Promise<{id: string, node: LoomNodeValue}>;
  updateNode(nodeID: string, node: LoomNodeValue) : Promise<void>;
};

// PromptInput Component (only visible before submission)
function PromptInput({ onSetPrompt }: { onSetPrompt: (prompt: string) => void }) {
  const [prompt, setPrompt] = useState("");

  const handleSetPromptClick = () => {
    if (prompt) {
      onSetPrompt(prompt);
    }
  };

  return (
    <div className="prompt-input">
      <textarea
        placeholder="Write or paste your prompt here..."
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        rows={20}
        cols={80}
      />
      <br />
      <button onClick={handleSetPromptClick}>Set Prompt</button>
    </div>
  );
}

function LoomNodeChildComponent({
  i,
  selectedChildID,
  nodeID,
  loomEvents,
  version
} : {
  i: number;
  selectedChildID: string | null;
  nodeID: string;
  loomEvents: LoomEvents;
  version: number;
}) {
  const [children, setChildren] = useState<{id: string, children: string[]}[] | null>(null);

  useEffect(() => {
    loomEvents.getChildren(nodeID).then((children) => {
      Promise.all(children.map(async (childID) => {
        const grandchildren = await loomEvents.getChildren(childID);
        return {id: childID, children: grandchildren};
      })).then((entries) => {
        setChildren(entries);
      });
    });
  }, [version, nodeID]);

  const style: Record<string, string> = {};
  if (nodeID == selectedChildID) {
    style['fontWeight'] = 'bold';
    style['textDecoration'] = 'underline';
  }
  if (children != null && children.length > 0) {
    style['color'] = 'green';
  }
  if (children != null && children.some((c) => c.children.length > 0)) {
    style['color'] = 'blue';
  }

  return (
    <span style={style}>{i+1}</span>
  );
}


function LoomNodeComponent({
  nodeID,
  loomEvents,
  version,
  depth
} : {
  nodeID: string;
  loomEvents: LoomEvents;
  version: number;
  depth: number;
}) {
  const [loomNode, setLoomNode] = useState<LoomNodeValue | null>(null);
  const [children, setChildren] = useState<string[] | null>(null);

  const isGenerating = loomEvents.isGenerating(nodeID);
  const selectedChildID : string | null =
    loomNode?.selectedChildID || (children != null && children.length > 0 && children[0]) || null;


  useEffect(() => {
    loomEvents.getNode(nodeID).then((node) => {
      if (node === null) {
        console.error('Failed to get node');
        return;
      }
      setLoomNode(node);
    });
  }, [version, nodeID]);

  useEffect(() => {
    loomEvents.getChildren(nodeID).then((children) => {
      setChildren(children);
    });
  }, [version, nodeID]);

  if (loomNode == null) {
    return <div>Loading...</div>;
  }
  if (loomNode.isDeleted) {
    return <div>(deleted)</div>
  }
  if (!nodeID) {
    return <div>ERROR: Invalid node ID</div>;
  }

  function handleSelectChild(childID: string) {
    loomEvents.getNode(nodeID).then((loomNode2) => {
      if (!loomNode2) {
        console.error('Failed to lookup node');
        return;
      }
      const newNode = {...loomNode2, selectedChildID: childID};
      setLoomNode(newNode);
      loomEvents.updateNode(nodeID, newNode).then(() => {
        loomEvents.incrementVersion();
      });
    })
  }

  function handleGenerateChildren() {
    if (isGenerating) {
      return;
    }
    loomEvents.generateChildren(nodeID).then((newChildren) => {
      loomEvents.getNode(nodeID).then((loomNode2) => {
        if (!loomNode2) {
          console.error('Failed to lookup node');
          return;
        }
        Promise.all(newChildren.map((newChild) => {
          return loomEvents.createNode(loomEvents.document, nodeID, newChild);
        })).then((chs) => {
          loomEvents.incrementVersion();
        });
      });
    });
  }

  function handleScrollChild(delta: number) {
    if (!children || !selectedChildID) {
      return;
    }
    const currentIx = children.indexOf(selectedChildID);
    if (currentIx != -1) {
      const i = currentIx + delta;
      if (0 <= i && i < children.length) {
        handleSelectChild(children[i]);
      }
    }
  }

  function handleDelete() {
    if (!window.confirm(`Are you sure you want to delete this node: ${loomNode?.text || '<no text>'}?`)) {
      return;
    }
    loomEvents.getNode(nodeID).then((loomNode2) => {
      if (!loomNode2) {
        console.error('Failed to lookup node');
        return;
      }
      loomEvents.updateNode(nodeID, {...loomNode2, isDeleted: true}).then(() => {
        if (loomNode2.parentID != null) {
          loomEvents.invalidateChildCache(loomNode2.parentID);
        }
        loomEvents.incrementVersion();
      });
    });
  }

  function handleAddChild() {
    loomEvents.getNode(nodeID).then((loomNode2) => {
      if (!loomNode2) {
        console.error('Failed to lookup node');
        return;
      }
      loomEvents.createNode(loomEvents.document, nodeID, '(New child)').then(({id, node}) => {
        loomEvents.incrementVersion();
        if (selectedChildID == null) {
          handleSelectChild(id);
        }
      });
    });
  }

  const hasChildren = !!(children && children.length > 0);


  return (
    <>
      <div className="loom-node">
        <EditableText
          value={loomNode?.text || ''}
          rows={4}
          disabled={!loomEvents.isOwner}
          trySetValue={async (newText: string) => {
            const loomNode2 = await loomEvents.getNode(nodeID);
            if (loomNode2 != null) {
              const newLoomNode = {...loomNode2, text: newText};
              setLoomNode(newLoomNode);
              await loomEvents.updateNode(nodeID, newLoomNode);
            } else {
              alert('Failed to edit node.');
            }
          }}
        />
        <table border={1}>
          <tr>
            <td>{depth+1}.</td>
            {hasChildren &&
              <td style={{cursor: 'pointer'}}
                  onClick={() => handleScrollChild(-1)}>⇦</td>}
            {hasChildren && children.map((childID, i) =>
              <td style={{cursor: 'pointer'}}
                  onClick={() => handleSelectChild(childID)}>
                <LoomNodeChildComponent i={i} selectedChildID={selectedChildID} nodeID={childID} loomEvents={loomEvents} version={version} />
              </td>
            )}
            {hasChildren &&
              <td style={{cursor: 'pointer'}}
                  onClick={() => handleScrollChild(1)}>⇨</td>}
            {loomEvents.isOwner && (isGenerating ?
              (<td><span className="loom-spinner"><LoadingSpinner size="tiny"/></span></td>)
              :
              (<td onClick={handleGenerateChildren}
                   style={{ cursor: 'pointer' }}
                   title="AI Completion">
                <span className="generate-icon">✨</span>
              </td>))}
            {loomEvents.isOwner &&
              <td className="add-child"
                    onClick={handleAddChild}
                    style={{cursor: 'pointer'}}
                    title="Add Child"
              ><span>➕</span></td>}
            
            {loomEvents.isOwner && loomNode.parentID != null &&
              <td
                onClick={handleDelete}
                style={{ cursor: 'pointer' }}
                title="Delete"
              ><span>🗑️</span></td>}
          </tr>
        </table>
      </div>
      {selectedChildID != null &&
        <LoomNodeComponent nodeID={selectedChildID} loomEvents={loomEvents} version={version} depth={depth+1} />
      }
    </>
  );
    
}

function LoomSidebar({
  loomEvents,
  version,
}: {
  loomEvents: LoomEvents;
  version: number;
}) {
  return (
    <div className="sidebar">
      <DocOptions loomEvents={loomEvents} version={version} />
    </div>
  );
}

function DocOptions({
  loomEvents,
  version
}: {
  loomEvents: LoomEvents;
  version: number;
}) {
  const [docValue, setDocValue] = useState<DocumentValue | null>(null);
  const loomStore = useMemo(() => new FireLoomStore(), []);

  var docID = loomEvents.document;

  useEffect(() => {
    getCurrentUser().then((user) => {
      loomStore.getDocument(docID).then((docValue) => {
        setDocValue(docValue);
      });
    });
  }, [docID, loomStore, version]);


  async function trySetMaxTokens(s: string): Promise<void> {
    const maxTokens = parseInt(s);

    if (isNaN(maxTokens) || maxTokens < 10 || maxTokens > 2048) {
      throw new Error('max tokens must be between 10 and 2048');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, maxTokens}});
    loomEvents.incrementVersion();
  }

  async function trySetNumSamples(s: string): Promise<void> {
    const numSamples = parseInt(s);
    if (isNaN(numSamples) || numSamples <= 0 || numSamples > 10) {
      throw new Error('num samples must be between 1 and 10');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, numSamples}});
    loomEvents.incrementVersion();
  }

  async function trySetTemperature(s: string): Promise<void> {
    const temperature = parseFloat(s);
    if (isNaN(temperature) || temperature < 0 || temperature > 2) {
      throw new Error('temperature must be between 0 and 2');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, temperature}});
    loomEvents.incrementVersion();
  }

  async function togglePublic() {
    if (docValue == null) {
      return;
    }
    const newPublic = !docValue.isPublic;
    if (newPublic) {
      if (!window.confirm("Are you sure you want to make this document public?")) {
        return;
      }
    } else {
      if (!window.confirm("Are you sure you want to make this document private?")) {
        return;
      }
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, isPublic: newPublic});
    loomEvents.incrementVersion();
  }


  return (docValue === null) ? <div>Loading...</div> : 
    !loomEvents.isOwner ? <div></div> : (
    <div className="doc-options">
      <ul>
        <li>Is public: {docValue.isPublic ? 'Yes' : 'No'}
          <button className="round-button" onClick={togglePublic}>{docValue.isPublic ? 'Make private' : 'Make public'}</button>
        </li>
        <li>Max tokens per sample: <EditableText cols={4} value={'' + docValue.samplingOptions.maxTokens} trySetValue={trySetMaxTokens}/></li>
        <li>Number of samples: <EditableText cols={3} value={'' + docValue.samplingOptions.numSamples} trySetValue={trySetNumSamples}/></li>
        <li>Temperature: <EditableText cols={4} value={'' + docValue.samplingOptions.temperature} trySetValue={trySetTemperature}/></li>
        <li>Model: Llama 3.1 405B Base (BF16), Hyperbolic.xyz</li>
      </ul>
    </div>
  );
}


// Define the route parameter types
type RouteParams = {
  documentID: string;
}

const tooltipText = `Loom interface.
Use the sidebar to view and edit document options.
Every node has text and icons displayed below.
Children of a node are listed with numbers below a node's text.
Underlined child is selected and displayed below.
Green children have children of their own.
Blue children have grandchildren.
Click on the pencil (✏️) to edit text.
Click on the arrows to navigate between children.
Click on a child number to select it.
Click on the sparkles (✨) to generate children with AI.
Click on the plus (➕) to add a child.
Click on the trash can (🗑️) to delete a node.`;

// Main Loom Component
function Loom() {
  const [isLoading, setIsLoading] = useState(true);
  const [prompt, setPrompt] = useState<string | null>(null); // Store the prompt after submission
  const [version, setVersion] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [documentValue, setDocumentValue] = useState<DocumentValue | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate | null>(null);
  const [templatesType, setTemplatesType] = useState<'personal' | 'public'>('personal');
  const [templates, setTemplates] = useState<PromptTemplate[]>([]);
  const [conversations, setConversations] = useState<Conversation[] | null>(null);

  const { documentID } = useParams<RouteParams>(); // Typed `useParams` call
  const loomStore = useMemo(() => new FireLoomStore(), []);
  const isGeneratingMap = useMemo<Record<string, boolean>>(() => ({}), [documentID]);
  const nonOwnerCache = useMemo<Record<string, LoomNodeValue>>(() => ({}), [documentID]);

  if (documentID === undefined) {
    throw new Error('Expected document name');
  }

  const document: string = documentID;

  const isOwner = user != null && documentValue != null && user.uid == documentValue.ownerID;

  useEffect(() => {
    getCurrentUser().then((user) => {
      loomStore.getConversations().then((conversations) => {setConversations(conversations)});
      console.log('use effect, templateType=', templatesType);
      loomStore.getTemplates(templatesType === 'public').then((templates) => {setTemplates(templates)});
      loomStore.getDocument(document).then((docValue) => {
        if (!docValue) {
          console.error('Document not found');
          return;
        }

        const rootID = docValue.rootNodeID;
        var rootNode = null;
        const finishInit = () => {
          setUser(user);
          setIsLoading(false);
          setDocumentValue(docValue);
        };
        if (rootID != null) {
          loomStore.getNode(rootID).then((rootNode) => {
            if (rootNode == null) {
              console.error('Failed to get root node');
              finishInit();
              return;
            }
            setPrompt(rootNode.text);
            finishInit();
          }).catch((err: any) => {
            console.error('Failed to get root node: ' + err.message);
            finishInit();
          });
        } else {
          finishInit();
        }
      }).catch((err: any) => {
        console.log('Failed permission check: ' + err.message);
        setIsLoading(false);
      });
    });
  }, [document, loomStore, templatesType]);

  if (documentValue == null && !isLoading) {
    return <Navigate to="/auth" />;
  }

  const handleSetPrompt = (submittedPrompt: string) => {
    setPrompt(submittedPrompt);
    console.log('submittedPrompt', submittedPrompt);
    loomStore.createNode(document, null, submittedPrompt).then(({id, node}) => {
      loomStore.getDocument(document).then((doc) => {
        if (doc != null) {
          const newVal = {...doc, rootNodeID: id};
          loomStore.updateDocument(document, newVal);
          setDocumentValue(newVal);
        }
      });
    });
  };

  function incrementVersion() {
    setVersion((version) => version + 1);
  }

  function getDocument(documentID: string): Promise<DocumentValue | null> {
    return loomStore.getDocument(documentID);
  }

  async function updateDocument(documentID: string, documentVal: DocumentValue) : Promise<void> {
    setDocumentValue(documentVal);
    if (isOwner) {
      await loomStore.updateDocument(documentID, documentVal);
    }
  }

  async function getNode(nodeID: string) : Promise<LoomNodeValue | null> {
    if (!isOwner && nonOwnerCache[nodeID]) {
      return nonOwnerCache[nodeID];
    }
    return await loomStore.getNode(nodeID);
  }

  function getChildren(nodeID: string) : Promise<string[]> {
    return loomStore.getChildren(document, nodeID);
  }

  function createNode(document: string, parentID: string | null, text: string) : Promise<{id: string, node: LoomNodeValue}> {
    return loomStore.createNode(document, parentID, text);
  }

  async function updateNode(nodeID: string, node: LoomNodeValue) : Promise<void> {
    if (isOwner) {
      await loomStore.updateNode(nodeID, node);
    } else {
      nonOwnerCache[nodeID] = node;
    }
  }

  async function getTextAtNode(nodeID: string | null): Promise<string[]> {

    const res = [];
    while (nodeID != null) {
      var nodeValue = await loomStore.getNode(nodeID);
      if (nodeValue == null) {
        break;
      }
      res.push(nodeValue.text);
      nodeID = nodeValue.parentID;
    }
    res.reverse();
    return res;
  }

  async function copyToClipboard() {
    const res: string[] = [];
    var nodeID = documentValue?.rootNodeID || null;
    while (nodeID != null) {
      const node = await getNode(nodeID);
      if (node == null) {
        break;
      }
      res.push(node.text || '');
      nodeID = node.selectedChildID;
    }
    const text = res.join('');
    await navigator.clipboard.writeText(text);

  }

  async function getConvoTranscript(conversation: Conversation): Promise<Transcription | null> {
    if (!conversation.transcribed || !conversation.transcriptID) {
      throw new Error('Conversation has not been transcribed yet');
    }
    return loomStore.getTranscription(conversation.id, conversation.transcriptID)
  }

  const loomEvents: LoomEvents = {
    document,
    isOwner,
    incrementVersion,
    getDocument,
    updateDocument,
    getNode,
    getChildren,
    invalidateChildCache: (nodeID: string) => {
      loomStore.invalidateChildCache(nodeID);
    },
    createNode,
    updateNode,
    isGenerating: (nodeID: string) => {
      return isGeneratingMap[nodeID] || false;
    },

    generateChildren: async (nodeID: string) => {
      isGeneratingMap[nodeID] = true;
      incrementVersion();

      const textArr = await getTextAtNode(nodeID);
      const text = textArr.join('');

      // Set up the parameters for the API call
      const body: {model: ModelType, prompt: string, max_tokens: number, n: number, temperature: number} = {
        prompt: text,
        max_tokens: documentValue?.samplingOptions?.maxTokens || 100,
        n: documentValue?.samplingOptions?.numSamples || 5,
        temperature: documentValue?.samplingOptions?.temperature || 1.0,
        model: 'meta-llama/Meta-Llama-3.1-405B',
      };
      try {
        const completions = await callCompletions(body);
        return completions;
      } catch(err: any) {
        alert(err.message);
        return [];
      } finally {
        isGeneratingMap[nodeID] = false;
        incrementVersion();
      }
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="loom-container">
      <header>
        <h1 className="title">Loom</h1>
      </header>
      {prompt == null ? 
        (isOwner ? (
          <div className="prompt-selection-container">
            <TemplatesSidebar
              templates={templates}
              selectedTemplate={selectedTemplate}
              onSelectTemplate={setSelectedTemplate}
              templatesType={templatesType}
              onTemplateTypeChange={(v) => setTemplatesType(v)}
            />
            <div className="prompt-input-container">
              {selectedTemplate ? (
                <PromptTemplateForm 
                  template={selectedTemplate}
                  conversations={conversations || []}
                  onSubmit={async ({template, values}) => {
                    console.log('template: ', template);
                    console.log('values: ', values);
                    const promptText = await textFromTemplatedPrompt({template, values}, getConvoTranscript);
                    console.log('promptText: ', promptText);
                    handleSetPrompt(promptText);
                  }}
                />
              ) : (
                <PromptInput onSetPrompt={handleSetPrompt} />
              )}
            </div>
          </div>
        ) : <div>Document not yet created</div>)
        :
        <div>
          <DocOptions loomEvents={loomEvents} version={version} />
          <hr/>
          <div className="parent-container">
            <div className="loom-body">
              <button className="round-button" onClick={copyToClipboard}>Copy</button>
              <Tooltip text={tooltipText}/>
              <LoomNodeComponent loomEvents={loomEvents} nodeID={documentValue?.rootNodeID || ''} version={version} depth={0} />
              <div style={{paddingTop: '20em'}}/>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Loom;
