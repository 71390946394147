import './HamburgerButton.css';

interface HamburgerButtonProps {
    isOpen: boolean;
    onClick: () => void;
}

export const HamburgerButton = ({ isOpen, onClick }: HamburgerButtonProps) => (
    <button 
        className={`hamburger ${isOpen ? 'open' : ''}`} 
        onClick={onClick}
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
    >
        <span></span>
        <span></span>
        <span></span>
    </button>
);