import { useState, useEffect } from 'react';
import { FireLoomStore } from '../db';
import { Conversation } from '../common/common_db';

export function useConversations(docStore: FireLoomStore) {
  const [conversations, setConversations] = useState<Conversation[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;
    let unsubscribe: (() => void) | null = null;
    
    const initialize = async () => {
      try {
        // Instead of splitting queries, we'll use a single listener for all conversations
        // that handles all states and changes
        unsubscribe = await docStore.subscribeToAllConversations(
          (convos) => {
            if (mounted) {
              setConversations(convos);
              setLoading(false);
            }
          },
          (err) => {
            console.error('Error in conversation subscription:', err);
            if (mounted) {
              setError(err);
              setLoading(false);
            }
          }
        );
      } catch (err) {
        console.error('Error initializing conversations:', err);
        if (mounted) {
          setError(err as Error);
          setLoading(false);
        }
      }
    };

    initialize();

    return () => {
      mounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docStore]);

  return { conversations, loading, error };
}