import React, { useState } from 'react';
import { AnnotationPromptDebugValue, AnnotationPromptResult } from '../../common/common_db';
import './AnnotationPromptDebugView.css';

const formatError = (error: unknown): string => {
  console.log("formatError")
  console.log(error)
    if (error instanceof Error) {
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else {
      return JSON.stringify(error, null, 2);
    }
  };

const ChevronIcon: React.FC<{ expanded: boolean }> = ({ expanded }) => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    className={`chevron-icon ${expanded ? 'expanded' : ''}`}
  >
    <path 
      d={expanded ? "M2 6l6 6 6-6" : "M6 2l6 6-6 6"}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

type ExpandableTextProps = {
  label: string;
  text: string;
  className?: string;
};

const ExpandableText: React.FC<ExpandableTextProps> = ({ label, text, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`expandable-text ${className || ''}`}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="expandable-text-button"
      >
        <ChevronIcon expanded={isExpanded} />
        <span>{label}</span>
      </button>
      {isExpanded && (
        <pre className="expandable-text-content">
          {text}
        </pre>
      )}
    </div>
  );
};
// Close (X) icon component
const CloseIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    className={className}
  >
    <path 
      d="M18 6L6 18M6 6l12 12" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const MaximizeIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    className={className}
  >
    <path 
      d="M3 3h4v2H5v2H3V3zm14 0h-4v2h2v2h2V3zM3 17h4v-2H5v-2H3v4zm14 0h-4v-2h2v-2h2v4z"
      fill="currentColor"
    />
  </svg>
);

// Helper component to render highlighted text based on error index
const HighlightedText: React.FC<{
  text: string;
  errorIndex?: number;
}> = ({ text, errorIndex }) => {
  if (typeof errorIndex === 'undefined') {
    return <pre className="comparison-panel-content">{text}</pre>;
  }

  const beforeError = text.slice(0, errorIndex);
  const afterError = text.slice(errorIndex);

  return (
    <pre className="comparison-panel-content">
      <span className="highlight-success">{beforeError}</span>
      <span className="highlight-error">{afterError}</span>
    </pre>
  );
};

// Text panel component for displaying either transcript or LLM text
type TextPanelProps = {
  title: string;
  content: string;
  errorIndex?: number;
};

const TextPanel: React.FC<TextPanelProps> = ({ title, content, errorIndex }) => (
  <div className="comparison-panel">
    <h3 className="comparison-panel-title">{title}</h3>
    <HighlightedText text={content} errorIndex={errorIndex} />
  </div>
);

// Main comparison modal component
type ComparisonModalProps = {
  result: AnnotationPromptResult;
  onClose: () => void;
};

const ComparisonModal: React.FC<ComparisonModalProps> = ({ result, onClose }) => {
  // Close on backdrop click, prevent closing when clicking modal content
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const error = result.error && (
    typeof result.error === 'string' 
      ? result.error 
      : result.error.message
  );

  const errorIndices = result.error && typeof result.error !== 'string' ? {
    transcriptTextIndex: result.error.transcriptTextIndex,
    llmTextIndex: result.error.llmTextIndex
  } : undefined;

  return (
    <div className="comparison-modal-backdrop" onClick={handleBackdropClick}>
      <div className="comparison-modal">
        <div className="comparison-modal-header">
          <h2 className="comparison-modal-title">
            Chunk Comparison View
          </h2>
          <button 
            className="comparison-modal-close"
            onClick={onClose}
            aria-label="Close comparison view"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="comparison-modal-content">
          {error && (
            <div className="comparison-error-banner">
              {error}
            </div>
          )}
          <div className="comparison-panels-container">
            <TextPanel 
              title="Transcript Text" 
              content={result.transcriptChunkText}
              errorIndex={errorIndices?.transcriptTextIndex}
            />
            <TextPanel 
              title="LLM Response" 
              content={result.llmText}
              errorIndex={errorIndices?.llmTextIndex}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
interface AnnotationResultItemProps {
  result: AnnotationPromptResult;
}

// Updated AnnotationResultItem with maximize functionality
const AnnotationResultItem: React.FC<AnnotationResultItemProps> = ({ result }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  
  return (
    <>
      <div className="result-item">
        <div className="result-item-controls">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="result-item-header"
          >
            <div className="result-item-title">
              <ChevronIcon expanded={isExpanded} />
              <span>
                Chunk Preview: {result.chunkPreviewText.slice(0, 50)}...
              </span>
            </div>
            <div className="result-item-count">
              {result.annotationIDs.length} annotations
            </div>
          </button>
          <button
            className="maximize-button"
            onClick={() => setIsMaximized(true)}
            aria-label="Maximize comparison view"
          >
            <MaximizeIcon />
          </button>
        </div>
        
        {isExpanded && (
          <div className="result-item-content">
            <div className="result-section">
              <ExpandableText 
                label="Transcript Chunk" 
                text={result.transcriptChunkText}
                className="result-expandable"
              />
            </div>
            
            <div className="result-section">
              <ExpandableText 
                label="LLM Response" 
                text={result.llmText}
                className="result-expandable"
              />
            </div>
            
            <div className="result-units">
              <div className="result-unit">
                <div className="result-unit-label">Start Unit:</div>
                <div className="result-unit-content">
                  {result.startUnit.word}
                </div>
              </div>
              <div className="result-unit">
                <div className="result-unit-label">End Unit:</div>
                <div className="result-unit-content">
                  {result.endUnit.word}
                </div>
              </div>
            </div>
            
            {typeof result.error !== 'undefined' && (
              <div className="result-error">
                <div className="result-error-label">Error:</div>
                <pre className="result-error-content">
                  {formatError(result.error)}
                </pre>
              </div>
            )}
            
            <div className="result-annotations">
              <div className="result-annotations-label">Annotation IDs:</div>
              <div className="result-annotations-list">
                {result.annotationIDs.map(id => (
                  <span key={id} className="annotation-id">
                    {id.slice(0, 8)}...
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {isMaximized && (
        <ComparisonModal 
          result={result} 
          onClose={() => setIsMaximized(false)} 
        />
      )}
    </>
  );
};

type AnnotationPromptDebugViewProps = {
  debug: AnnotationPromptDebugValue;
};

const AnnotationPromptDebugView: React.FC<AnnotationPromptDebugViewProps> = ({ debug }) => {
  const [showResults, setShowResults] = useState(false);
  
  const totalAnnotations = debug.results.reduce(
    (sum, result) => sum + result.annotationIDs.length, 
    0
  );
  const successfulChunks = debug.results.filter(r => !r.error).length;

  return (
    <div className="debug-view">
      <div className="debug-section">
        <h3 className="debug-title">Prompt Debug Information</h3>
        
        <div className="debug-metadata">
          <div className="metadata-item">
            <div className="metadata-label">ID:</div>
            <div className="metadata-content">{debug.id}</div>
          </div>
          <div className="metadata-item">
            <div className="metadata-label">Owner ID:</div>
            <div className="metadata-content">{debug.ownerID}</div>
          </div>
          <div className="metadata-item">
            <div className="metadata-label">Conversation ID:</div>
            <div className="metadata-content">{debug.conversationID}</div>
          </div>
          <div className="metadata-item">
            <div className="metadata-label">Model ID:</div>
            <div className="metadata-content">{debug.modelID}</div>
          </div>
          <div className="metadata-item">
            <div className="metadata-label">Creation Time:</div>
            <div className="metadata-content">
              {new Date(debug.creationEpochTime).toLocaleString()}
            </div>
          </div>
          <div className="metadata-item">
            <div className="metadata-label">Annotation Group ID:</div>
            <div className="metadata-content">
              {debug.annotationGroupID || 'None'}
            </div>
          </div>
        </div>

        <div className="debug-prompts">
          <ExpandableText label="User Prompt" text={debug.userPrompt} />
          <ExpandableText label="System Prompt" text={debug.systemPrompt} />
        </div>
      </div>

      <div className="debug-section">
        <div className="results-header">
          <h3 className="debug-title">Results Summary</h3>
          <div className="results-summary">
            {successfulChunks} successful chunks | {totalAnnotations} total annotations
          </div>
        </div>

        <button
          onClick={() => setShowResults(!showResults)}
          className="results-toggle"
        >
          <ChevronIcon expanded={showResults} />
          <span>Show Results</span>
        </button>

        {showResults && (
          <div className="results-list">
            {debug.results.map((result, index) => (
              <AnnotationResultItem key={index} result={result} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnotationPromptDebugView;