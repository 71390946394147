import './InfoPageContent.css';

export const PageContent = ({ title, children }: { title: string, children: React.ReactNode }) => {
    return (
      <div className="page-container">
        <div className="content-card">
          <h1>{title}</h1>
          {children}
        </div>
      </div>
    );
  };