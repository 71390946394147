import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { getCurrentUser } from '../firebase_config';
import { FireLoomStore } from '../db'; 
import './Conversations.css';
import { Conversation, ConvoVisibility, TranscriptionStatus} from '../common/common_db';
import { callTranscribeMedia } from '../callables';
import LoadingSpinner from '../components/LoadingSpinner';
import DeleteButton from '../components/DeleteButton';
import { useConversations } from '../hooks/useConversations';

interface ConversationCardProps {
  conversation: Conversation;
  currentUserID: string;
  onDelete: (id: string) => void;
  onClick: () => void;
  onRetry: (conversation: Conversation) => void;
}

const VisibilityIcon: React.FC<{ visibility: ConvoVisibility }> = ({ visibility }) => {
  const getIcon = () => {
    switch (visibility) {
      case 'public':
        return <span title="Public">🌎</span>;
      case 'private':
        return <span title="Private">🔒</span>;
      case 'research':
        return <span title="Research">🔬</span>;
    }
  };
  
  return (
    <div className="visibility-indicator" title={`Visibility: ${visibility}`}>
      {getIcon()}
    </div>
  );
};

const TranscriptionStatusComponent: React.FC<{
  status: TranscriptionStatus;
  errorMessage?: string;
  onRetry?: () => void;
}> = ({ status, errorMessage, onRetry }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  switch (status) {
    case 'processing':
      return (
        <div className="transcription-status processing">
          <LoadingSpinner size="small" />
          <span>Processing transcription...</span>
        </div>
      );
    case 'failed':
      return (
        <div className="transcription-status failed">
          <div 
            className="error-message"
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          >
            <span>⚠️ Transcription failed</span>
            {isTooltipVisible && errorMessage && (
              <div className="error-tooltip">
                <div className="error-tooltip-content">{errorMessage}</div>
              </div>
            )}
          </div>
          {onRetry && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onRetry();
              }}
              className="retry-button"
            >
              Retry Transcription
            </button>
          )}
        </div>
      );
    case 'pending':
      return (
        <div className="transcription-status pending">
          <LoadingSpinner size="small" />
          <span>Starting transcription...</span>
        </div>
      );
    default:
      return null;
  }
};

export const ConversationCard: React.FC<ConversationCardProps> = ({
  conversation,
  currentUserID,
  onDelete,
  onClick,
  onRetry,
}) => {
  const isOwner = conversation.ownerID === currentUserID;
  const formattedDate = new Date(conversation.creationEpochTime).toLocaleDateString();
  const isClickable = conversation.transcriptionStatus === 'completed' || conversation.transcribed;

  return (
    <div 
      className={`conversation-card ${conversation.transcriptionStatus} ${isClickable ? 'clickable' : ''}`}
      onClick={isClickable ? onClick : undefined}
    >
      <div className="conversation-header">
        <div className="conversation-meta">
          <VisibilityIcon visibility={conversation.visibility} />
          {isOwner && (
            <div className="owner-badge" title="You own this conversation">
              👤
            </div>
          )}
        </div>
        {isOwner && conversation.transcribed && (
          <div className="conversation-actions">
            <DeleteButton
              onDelete={() => onDelete(conversation.id)}
              itemType="conversation"
              className="conversation-delete-btn"
            />
          </div>
        )}
      </div>

      <div className="conversation-content">
        <h3 className="conversation-name">{conversation.name}</h3>
        <div className="conversation-footer">
          <span className="conversation-date">{formattedDate}</span>
          <TranscriptionStatusComponent 
            status={conversation.transcriptionStatus}
            errorMessage={conversation.errorMessage}
            onRetry={isOwner ? () => onRetry(conversation) : undefined}
          />
        </div>
      </div>
    </div>
  );
};

interface ConversationListProps {
  conversations: Array<Conversation>;
  currentUserID: string;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
  onRetryTranscription: (conversation: Conversation) => void;
}

export const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  currentUserID,
  onDelete,
  onSelect,
  onRetryTranscription
}) => {
  return (
    <div className="conversation-list">
      {conversations.map((conversation) => (
        <ConversationCard
          key={conversation.id}
          conversation={conversation}
          currentUserID={currentUserID}
          onDelete={onDelete}
          onClick={() => onSelect(conversation.id)}
          onRetry={() => onRetryTranscription(conversation)}
        />
      ))}
    </div>
  );
};

function Conversations() {
  const [docStore] = useState(new FireLoomStore());
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [mediaFormKey, setMediaFormKey] = useState(0);
  const [transcriptFormKey, setTranscriptFormKey] = useState(0);

  const [mediaFile, setMediaFile] = useState<File | null>(null);
  const [transcriptFile, setTranscriptFile] = useState<File | null>(null);
  const [uploadMode, setUploadMode] = useState<'automatic' | 'manual'>('automatic');
  const navigate = useNavigate();

  const { conversations: convosFromHook, loading: conversationsLoading } = useConversations(docStore);

  useEffect(() => {
    getCurrentUser().then((currUser) => {
      setUser(currUser);
      if (currUser) {
        setUserId(currUser.uid);
      }
      setLoading(false);
    });
  }, []);

  if (user == null && !loading) {
    return <Navigate to="/auth" />;
  }

  async function handleAutomaticUpload(e: React.FormEvent) {
    e.preventDefault();
    if (!mediaFile) {
      return;
    }
    setIsUploading(true);
    const convo = await docStore.createConversation(mediaFile)
    setMediaFile(null);
    setIsUploading(false);
    (e.target as HTMLFormElement).reset();
    setMediaFormKey((key) => key + 1);

    await callTranscribeMedia(convo);
  }

  async function handleManualUpload(e: React.FormEvent) {
    e.preventDefault();
    if (!mediaFile || !transcriptFile) {
      return;
    }
    setIsUploading(true);
    const convo = await docStore.createManuallyTranscribedConversation(mediaFile, transcriptFile);
    setMediaFile(null);
    setTranscriptFile(null);
    setIsUploading(false);
    (e.target as HTMLFormElement).reset();
    setMediaFormKey((key) => key + 1);
    setTranscriptFormKey((key) => key + 1);
  }

  async function handleDeleteConversation(convoID: string) {
    await docStore.deleteConversation(convoID);
  }

  function handleSelectConversation(convoID: string) {
    console.log("CLICK")
    navigate(`/conversation/${convoID}`);
  }

  async function handleRetryTranscription(conversation: Conversation) {
    try {
      await docStore.updateConversation(conversation.id, {
        ...conversation,
        transcriptionStatus: 'pending',
        errorMessage: "",
      });
      await callTranscribeMedia(conversation);
    } catch (error) {
      console.error('Error retrying transcription:', error);
    }
  }

  return (
    <div>
      <header>
        <h1 className="title">Conversations</h1>
      </header>
      
      {loading || conversationsLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="upload-container">
            <div className="upload-mode-selector">
              <button
                className={`mode-button ${uploadMode === 'automatic' ? 'active' : ''}`}
                onClick={() => setUploadMode('automatic')}
              >
                Automatic Transcription
              </button>
              <button
                className={`mode-button ${uploadMode === 'manual' ? 'active' : ''}`}
                onClick={() => setUploadMode('manual')}
              >
                Manual Transcript Upload
              </button>
            </div>

            <form 
              onSubmit={uploadMode === 'automatic' ? handleAutomaticUpload : handleManualUpload}
              className="upload-form"
            >
              <div className="file-input-group">
                <label className="file-input-label">Media File:</label>
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    accept="audio/*,video/*"
                    onChange={(e) => setMediaFile(e.target.files ? e.target.files[0] : null)}
                    disabled={isUploading}
                    className="file-input"
                    key={mediaFormKey}
                  />
                </div>
              </div>

              {uploadMode === 'manual' && (
                <div className="file-input-group">
                  <label className="file-input-label">Transcript JSON:</label>
                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      accept=".json"
                      onChange={(e) => setTranscriptFile(e.target.files ? e.target.files[0] : null)}
                      disabled={isUploading}
                      className="file-input"
                      key={transcriptFormKey}
                    />
                  </div>
                </div>
              )}

              <div className="spinner-container">
                {isUploading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    type="submit"
                    disabled={!mediaFile || (uploadMode === 'manual' && !transcriptFile)}
                    className="upload-button"
                  >
                    {uploadMode === 'automatic' ? 'Upload and Transcribe' : 'Upload with Transcript'}
                  </button>
                )}
              </div>
            </form>
          </div>

          <ConversationList
            conversations={convosFromHook || []}
            onDelete={handleDeleteConversation}
            onSelect={handleSelectConversation}
            onRetryTranscription={handleRetryTranscription}
            currentUserID={userId || ''}
          />
        </div>
      )}
    </div>
  );
}

export default Conversations;
