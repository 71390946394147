import React, { useState } from 'react';
import { ModelType, MODEL_INFORMATION } from '../../common/llm_clients';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownOption, Dropdown } from '../Dropdown';
import { selectGroups } from './annotationPanelSlice';
import {
  selectSystemPrompt,
  selectUserPrompt,
  selectSelectedGroups,
  selectIsLoading,
  selectSelectedModel,
  setUserPrompt,
  setModel,
  toggleSelectedGroup,
  makeQuery
} from './queryPanelSlice';
import './QueryPanel.css';
import { useAppDispatch, useFireLoomStore } from '../../hooks';

const modelOptions: DropdownOption<ModelType>[] = 
Object.keys(MODEL_INFORMATION).map(key => ({ 
  value: key as ModelType, 
  label: key.split('/').pop()?.replace(/-\d{8}$/, '') ?? key }));

const SystemPromptSection: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const systemPrompt = useSelector(selectSystemPrompt);

  return (
    <div className="system-prompt-section">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="system-prompt-toggle"
      >
        <span className="toggle-icon">{isExpanded ? '▼' : '▶'}</span>
        <span>System Prompt</span>
      </button>
      {isExpanded && (
        <div className="system-prompt-content">
          {systemPrompt}
        </div>
      )}
    </div>
  );
};

const ExampleSelector: React.FC = () => {
  const selectedGroups = useSelector(selectSelectedGroups);
  const annotationGroups = useSelector(selectGroups);
  const dispatch = useDispatch();

  // Stub: This would fetch available groups from your annotation panel state
  const availableGroups = annotationGroups

  return (
    <div className="example-selector">
      <h3>Select Annotation Groups to include as examples</h3>
      <div className="example-groups">
        {availableGroups.map(group => (
          <label key={group.id} className="example-group-item">
            <input
              type="checkbox"
              checked={selectedGroups.includes(group.id)}
              onChange={() => dispatch(toggleSelectedGroup(group.id))}
            />
            <span>{group.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export const QueryPanel: React.FC = () => {
  const dispatch = useAppDispatch()
  const loomStore = useFireLoomStore()
  const userPrompt = useSelector(selectUserPrompt);
  const isLoading = useSelector(selectIsLoading);
  const selectedModel = useSelector(selectSelectedModel);

  const handleSubmit = () => {
    dispatch(makeQuery({ loomStore }));
  };

  return (
    <div className="query-panel">
      <div className="query-panel-content">
        <SystemPromptSection />
        
        <div className="model-selector">
          <label>Model</label>
          <Dropdown
            options={modelOptions}
            value={selectedModel}
            onChange={(model) => dispatch(setModel(model))}
          />
        </div>

        <div className="prompt-input-section">
          <label>Your Prompt</label>
          <textarea
            value={userPrompt}
            onChange={(e) => dispatch(setUserPrompt(e.target.value))}
            placeholder="Describe your annotation task in detail here..."
            className="prompt-textarea"
          />
        </div>

        {/* <ExampleSelector /> */}

        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className={`query-button ${isLoading ? 'loading' : ''}`}
        >
          {isLoading ? (
            <div className="loader-container">
              <div className="loader"></div>
              <span>Processing...</span>
            </div>
          ) : (
            'Make Query'
          )}
        </button>
      </div>
    </div>
  );
};