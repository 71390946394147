import { useEffect, useRef } from "react";

export const DiscordCallback = () => {
    const processed = useRef(false);
    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code');
        console.log("code:", code);

        if (!code) {
            // No code means user cancelled - close the popup
            if (window.opener) {
              window.close();
            }
            return;
          }

        if (!code || !window.opener || processed.current) return;
        

        processed.current = true;

        window.opener.postMessage({
            type: 'DISCORD_AUTH_SUCCESS',
            code
        }, window.location.origin);
        
        window.close();
    }, []);
    return <div>Connecting your Discord account...</div>;
};