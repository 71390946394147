
import React from 'react';
import { PageContent } from '../components/InfoPageContent';

function Home() {
  return (
    <PageContent title="Welcome to Inkstream">
      <p className='content-text'>A collection of AI tools for conversational analysis. Upload audio/video recordings of conversations, do open ended analysis on the transcripts through a Loom UI with base-models, and make structured templates for your most interesting prompts. Currently in early-pre-alpha and mostly intended for dog-fooding use by the research team.</p>
    </PageContent>
    
  );
}

export default Home;
