import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import './AudioPlayer.css';
// Define the interface for the methods we want to expose
export interface AudioPlayerHandle {
  seekToTime: (seconds: number) => void;
}

interface AudioPlayerProps {
  audioUrl: string;
}

export const AudioPlayer = forwardRef<AudioPlayerHandle, AudioPlayerProps>(
  ({ audioUrl }, ref) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    const seekToTime = (seconds: number) => {
      if (audioRef.current) {
        audioRef.current.currentTime = seconds;
      }
    };

    // Expose seekToTime method to parent components
    useImperativeHandle(ref, () => ({
      seekToTime
    }));

    return (
      <div className="audio-player">
        <audio ref={audioRef} controls src={audioUrl} />
      </div>
    );
  }
);