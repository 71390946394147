import React from 'react';
import './ConfirmationDialog.css';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = "Confirm",
  cancelText = "Cancel"
}) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-dialog-backdrop">
      <div className="confirmation-dialog">
        <h3 className="confirmation-dialog-title">{title}</h3>
        <p className="confirmation-dialog-message">{message}</p>
        <div className="confirmation-dialog-buttons">
          <button 
            className="confirmation-dialog-button-cancel" 
            onClick={(e) => {
              onCancel()
              e.stopPropagation()
            }}
          >
            {cancelText}
          </button>
          <button 
            className="confirmation-dialog-button-confirm" 
            onClick={(e) =>{
              onConfirm()
              e.stopPropagation()
            }}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;