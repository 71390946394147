import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { AnnotationPromptDebugValue } from '../common/common_db';
import { selectCurrentGroup } from '../components/transcriptSidePanel/annotationPanelSlice';
import { useAppSelector } from '../store';
import { useFireLoomStore } from '../hooks';

export interface AnnotationDebugValueContextType  {
    debugValue: AnnotationPromptDebugValue | null;
  }
  
  export const AnnotationDebugValueContext = createContext<AnnotationDebugValueContextType>({
    debugValue: null
  });
  
  interface AnnotationDebugValueProviderProps {
    children: ReactNode;
  }
  
  export const AnnotationDebugValueProvider = ({ children }: AnnotationDebugValueProviderProps): JSX.Element => {
    const [debugValue, setDebugValue] = useState<AnnotationPromptDebugValue | null>(null);
    const currentGroup = useAppSelector(selectCurrentGroup);
    const loomStore = useFireLoomStore();
    
    useEffect(() => {
      const fetchDebugValue = async () => {
        if (currentGroup && !currentGroup.madeByHuman && currentGroup.promptDebugValueID) {
          const value = await loomStore.getAnnotationDebugValue(currentGroup.promptDebugValueID);
          setDebugValue(value);
        } else {
          setDebugValue(null);
        }
      };
  
      fetchDebugValue();
    }, [currentGroup, loomStore]);
  
    
    return (
      <AnnotationDebugValueContext.Provider value={{ debugValue }}>
        {children}
      </AnnotationDebugValueContext.Provider>
    )
  };
  
  export const useAnnotationDebugValue = (): AnnotationDebugValueContextType => {
    const context = useContext(AnnotationDebugValueContext);
    if (!context) {
      throw new Error('useAnnotationDebugValue must be used within a AnnotationDebugValueProvider');
    }
    return context;
  };