import React, { useState } from 'react';
import { useAppDispatch, useFireLoomStore } from '../../hooks';
import { updateAnnotation, deleteAnnotation } from './annotationPanelSlice';
import { Annotation } from '../../common/transcripts';
import { TranscriptUnit } from '../../common/common_db';
import './AnnotationDetailView.css';

interface ContentKeyValuePair {
  key: string;
  value: string;
}

const MetadataField: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <div className="metadata-field">
    <span className="metadata-label">{label}:</span>
    <span className="metadata-value">
      {typeof value === 'number' 
        ? new Date(value).toLocaleString() 
        : value}
    </span>
  </div>
);

const TranscriptUnitDisplay: React.FC<{ label: string; unit: TranscriptUnit }> = ({ label, unit }) => (
  <div className="metadata-section">
    <h4>{label}</h4>
    <MetadataField label="Word" value={unit.word} />
    <MetadataField label="ID" value={unit.id.toString()} />
  </div>
);

const ContentKeyValueEditor: React.FC<{
  pair: ContentKeyValuePair;
  onChange: (updated: ContentKeyValuePair) => void;
  onDelete: () => void;
  isEditable: boolean;
}> = ({ pair, onChange, onDelete, isEditable }) => (
  <div className="content-pair">
    <div className="input-group">
      <label className="input-label">Key</label>
      <input
        className="content-input key-input"
        value={pair.key}
        onChange={(e) => onChange({ ...pair, key: e.target.value })}
        placeholder="Enter key"
        disabled={!isEditable}
      />
    </div>
    <div className="input-group">
      <label className="input-label">Value</label>
      <input
        className="content-input value-input"
        value={pair.value}
        onChange={(e) => onChange({ ...pair, value: e.target.value })}
        placeholder="Enter value"
        disabled={!isEditable}
      />
    </div>
    {isEditable && (
      <div className="content-pair-actions">
        <button 
          onClick={onDelete}
          className="delete-pair-button"
        >
          Delete
        </button>
      </div>
    )}
  </div>
);

const AnnotationDetailView: React.FC<{ 
  annotation: Annotation;
  groupID: string;
}> = ({ annotation, groupID }) => {
  const dispatch = useAppDispatch();
  const loomStore = useFireLoomStore();
  
  const [contentPairs, setContentPairs] = useState<ContentKeyValuePair[]>(
    Object.entries(annotation.content).map(([key, value]) => ({ key, value }))
  );
  
  const [isEditing, setIsEditing] = useState(false);

  const isEditable = annotation.madeByHuman;

  const handleAddPair = () => {
    setContentPairs([...contentPairs, { key: '', value: '' }]);
    setIsEditing(true);
  };

  const handleUpdatePair = (index: number, updated: ContentKeyValuePair) => {
    const newPairs = [...contentPairs];
    newPairs[index] = updated;
    setContentPairs(newPairs);
    setIsEditing(true);
  };

  const handleDeletePair = (index: number) => {
    setContentPairs(contentPairs.filter((_, i) => i !== index));
    setIsEditing(true);
  };

  const handleUpdateAnnotation = async () => {
    const updatedContent = contentPairs.reduce((acc, { key, value }) => {
      if (key.trim()) {
        acc[key.trim()] = value;
      }
      return acc;
    }, {} as Record<string, string>);

    try {
      await dispatch(updateAnnotation({
        loomStore,
        annotationID: annotation.id,
        groupID,
        updates: {
          content: updatedContent,
          lastModifiedTime: Date.now()
        }
      }));
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update annotation:', error);
    }
  };

  const handleDeleteAnnotation = async () => {
    try {
      await dispatch(deleteAnnotation({
        loomStore,
        annotationID: annotation.id,
        groupID
      }));
    } catch (error) {
      console.error('Failed to delete annotation:', error);
    }
  };

  return (
    <div className='annotation-detail-container'>
      <button 
        className='annotation-scroll-button'
        onClick={() => document.getElementById(`word-${annotation.startUnit.id}`)?.scrollIntoView({block: 'center'})}>
          Jump to Annotation</button>
      <div className="annotation-detail split-view">
        <div className="left-panel">
          <div className="panel-header">
            <h3>Content</h3>
            {isEditable && (
              <button 
                onClick={handleAddPair}
                className="add-content-button"
              >+ Add Key-Value Pair
              </button>
            )}
          </div>
          <div className="content-pairs-list">
            {contentPairs.map((pair, index) => (
              <ContentKeyValueEditor
                key={index}
                pair={pair}
                onChange={(updated) => handleUpdatePair(index, updated)}
                onDelete={() => handleDeletePair(index)}
                isEditable={isEditable}
              />
            ))}
            {contentPairs.length === 0 && (
              <div className="empty-state">
                No content pairs. {isEditable && 'Click "Add Key-Value Pair" to add content.'}
              </div>
            )}
          </div>
          {isEditable && isEditing && (
            <button 
              onClick={handleUpdateAnnotation}
              className="update-button"
            >
              Update Annotation
            </button>
          )}
        </div>

        <div className="right-panel">
          <div className="panel-header">
            <h3>Metadata</h3>
            {isEditable && (
              <button 
                onClick={handleDeleteAnnotation}
                className="delete-annotation-button"
              >
                Delete Annotation
              </button>
            )}
          </div>
          <div className="metadata-list">
            <MetadataField label="ID" value={annotation.id} />
            <MetadataField label="Author ID" value={annotation.authorID} />
            <MetadataField label="Created by" value={annotation.madeByHuman ? "Human" : "LLM"} />
            <MetadataField label="Conversation ID" value={annotation.conversationID} />
            <MetadataField label="Created" value={annotation.creationTime} />
            <MetadataField label="Last Modified" value={annotation.lastModifiedTime} />
            <MetadataField label="Group ID" value={annotation.groupID} />
            
            <div className="metadata-section">
              <h4>Tags</h4>
              <div className="tags-list">
                {annotation.tags.length > 0 ? (
                  annotation.tags.map(tag => (
                    <span key={tag} className="tag">{tag}</span>
                  ))
                ) : (
                  <span className="no-tags">No tags</span>
                )}
              </div>
            </div>

            {annotation.attributes && (
              <div className="metadata-section">
                <h4>Attributes</h4>
                {Object.entries(annotation.attributes).map(([key, value]) => (
                  <MetadataField key={key} label={key} value={value} />
                ))}
              </div>
            )}

            <TranscriptUnitDisplay label="Start Unit" unit={annotation.startUnit} />
            <TranscriptUnitDisplay label="End Unit" unit={annotation.endUnit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnotationDetailView;