
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPuID1CVGgg4jwR9-w0WFDmE8HJsR6aWI",
  authDomain: "webloom-d038a.firebaseapp.com",
  projectId: "webloom-d038a",
  storageBucket: "webloom-d038a.firebasestorage.app",
  messagingSenderId: "620957130863",
  appId: "1:620957130863:web:c8d5bb7469843fb2866d9a",
  measurementId: "G-7TXX66M0P8"
};


// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseDb = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);


export async function waitUntil(condition: () => boolean): Promise<void> {
  while (!condition()) {
    await new Promise(resolve => setTimeout(resolve, 50));
  }
}

let didFirstUserFetch = false;
let cachedUser: User | null = null;
let userListeners: ((user: User | null) => void)[] = [];

onAuthStateChanged(firebaseAuth, (user) => {
  cachedUser = user || null;
  for (const listener of userListeners) {
    listener(cachedUser);
  }
  userListeners = [];
  didFirstUserFetch = true;
});


export async function getCurrentUser(): Promise<User | null> {
  if (didFirstUserFetch) {
    return cachedUser;
  }
  return new Promise((resolve) => {
    var done = false;
    userListeners.push(function(res) {
      if (!done) {
        done = true;
        resolve(res);
      }
    });
  });
}

