import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { transcriptToTurns} from '../common/util';
import { 
    type Conversation,
    type LinearTurnsTranscript,
    type SpeakerTurn,
    type ConvoVisibility, 
    UserValue
    } from '../common/common_db';
import { Annotation, basicTurnFormatters, copyTextTurnFormatters, findUnitInRangable, stringifyRangable } from '../common/transcripts';
import { FireLoomStore } from '../db';
import QueryPanel from '../components/QueryPanel';
import {TranscriptBody} from '../components/TranscriptBody';
import './Conversation.css';
import uuid4 from 'uuid4';
import { 
  setConversation,
  setTranscript,
  setLoading,
  setError,
  setUserID,
  updateVisibility,
  selectConversation,
  selectTranscript,
  selectLoading,
  selectError,
  selectUserID,
} from './transcriptViewerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanel } from '../components/transcriptSidePanel/SidePanel';
import { setGroups } from '../components/transcriptSidePanel/annotationPanelSlice';
import { setSystemPrompt } from '../components/transcriptSidePanel/queryPanelSlice';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { AudioPlayer, AudioPlayerHandle } from '../components/AudioPlayer';

const LoadingSpinner = () => (
  <div className="loading-container">
    <div className="loading-spinner" />
  </div>
);

const ErrorMessage = ({ message }: { message: string }) => (
  <div className="error-message">
    Error: {message}
  </div>
);

interface SelectionRange {
  startId: number;
  endId: number;
}

type VisibilityType = ConvoVisibility

interface VisibilityDropdownProps {
  visibility: VisibilityType;
  isOwner: boolean;
  onVisibilityChange: (newVisibility: VisibilityType) => void;
}

const VisibilityDropdown: React.FC<VisibilityDropdownProps> = ({ visibility, isOwner, onVisibilityChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingVisibility, setPendingVisibility] = useState<VisibilityType | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleVisibilitySelect = (newVisibility: VisibilityType) => {
    setPendingVisibility(newVisibility);
    setIsOpen(false);
    setShowConfirmDialog(true);
  };

  const handleConfirm = () => {
    if (pendingVisibility) {
      onVisibilityChange(pendingVisibility);
      setPendingVisibility(null);
    }
    setShowConfirmDialog(false);
  };

  return (
    <div className="visibility-control" ref={dropdownRef}>
      <span className="visibility-label">Visibility:</span>
      <button 
        className={`visibility-badge ${isOwner ? 'clickable' : ''}`}
        onClick={(e) => isOwner && setIsOpen(!isOpen)}
      >
        {visibility}
      </button>

      {isOpen && isOwner && (
        <div className="visibility-dropdown">
          {(['private', 'public', 'research'] as VisibilityType[]).map(type => (
            <button
              key={type}
              className={`dropdown-item ${visibility === type ? 'active' : ''}`}
              onClick={() => handleVisibilitySelect(type)}
            >
              {type}
            </button>
          ))}
        </div>
      )}

      <ConfirmationDialog
        isOpen={showConfirmDialog}
        title="Change Visibility?"
        message={`Are you sure you want to change the visibility to ${pendingVisibility}?`}
        onConfirm={handleConfirm}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </div>
  );
};


//##########################################################
// # Transcript Viewer
//##########################################################

const TranscriptViewer = () => {
  const { conversationID } = useParams<{ conversationID: string }>();
  const dispatch = useDispatch();
  const [docStore] = useState(new FireLoomStore());
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);  // Note the type change here

// ... rest of component ...


  
  const conversation = useSelector(selectConversation);
  const transcript = useSelector(selectTranscript);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const userID = useSelector(selectUserID);

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        if (!conversationID) throw new Error('No conversation ID provided');

        const conversation = await docStore.getConversation(conversationID);
        if (!conversation) {
          throw new Error('Conversation not found');
        }

        if (!conversation.transcribed || !conversation.transcriptID) {
          throw new Error('Conversation has not been transcribed yet');
        }
        dispatch(setConversation(conversation));

        const transcription = await docStore.getTranscription(conversation.id, conversation.transcriptID);
        if (!transcription) {
          throw new Error('Transcription not found');
        }
        const linearTranscript = transcriptToTurns(transcription);
        dispatch(setTranscript(linearTranscript));
        dispatch(setLoading(false));

      } catch (err) {
        dispatch(setError(err instanceof Error ? err.message : 'An error occurred'));
        dispatch(setLoading(false));
      }
    };

    const fetchUserId = async () => {
      const userId = await docStore.getCurrentUserId();
      dispatch(setUserID(userId));
    };

    const fetchAnnotationGroups = async () => {
      if (!conversationID) {
        throw new Error('No conversation ID provided');
      }

      try {
        const groups = await docStore.getAnnotationGroupsFromConvoID(conversationID);
        console.log('Fetched annotation groups, CONVO comp:', groups);
        dispatch(setGroups(groups));
      } catch(err) {
        console.error('Failed to fetch annotation groups:', err);
        throw new Error(`Failed to fetch annotation groups, ${err}`);
      }
    }
    const fetchSystemPrompt = async () => {
      const res = await fetch(`/annotationSystemPrompt.txt`)
      const prompt = await res.text()

      dispatch(setSystemPrompt(prompt))
    }

    fetchUserId();
    fetchTranscript();
    fetchAnnotationGroups()
    fetchSystemPrompt();
  }, [conversationID, dispatch]);

  const handleVisibilityChange = async (newVisibility: ConvoVisibility) => {
    if (!conversationID || !conversation) return;
    
    const newConvo = {...conversation, visibility: newVisibility};
    await docStore.updateConversation(conversationID, newConvo);
    dispatch(updateVisibility(newVisibility));
  };

  const handleCopyTranscript = async () => {
    if (transcript) {
      const text = stringifyRangable(transcript.turns, copyTextTurnFormatters)
      await navigator.clipboard.writeText(text);
    }
  }

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!transcript || !conversation) {
    return <div className="no-data">No transcript data available</div>;
  }

  return (
    <div className="transcript-viewer">
      <AudioPlayer
        audioUrl={conversation.compositeMediaUrl} 
        ref={audioPlayerRef}
      />
      <div className="content-container">
        <div className="transcript-container">
          <div className="transcript-header">
            <h1 className="transcript-title">{conversation.name}</h1>
            <VisibilityDropdown
                visibility={conversation.visibility as VisibilityType}
                isOwner={conversation.ownerID === userID}
                onVisibilityChange={handleVisibilityChange}
              />
            <button className="copy-button" onClick={handleCopyTranscript}>Copy Transcript Text</button>
          </div>
          <TranscriptBody audioPlayerRef={audioPlayerRef}/>
        </div>
        <SidePanel/>
      </div>
    </div>
  );
};

export default TranscriptViewer;