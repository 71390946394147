import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';
import {BrowserView} from 'react-device-detect';
import './MainNav.css';


interface MainNavProps {
  user: User | null;
}

export const MainNav = ({ user }: MainNavProps) => {
  if (!user) return null;
  
  return (
    <nav className="main-nav">
      <BrowserView>
      <ul>
        <li><Link to="/documents">Loom</Link></li>
        <li><Link to="/conversations">Conversations</Link></li>
        <li><Link to="/templates">Templates</Link></li>
      </ul>
      </BrowserView>
    </nav>
  );
};
