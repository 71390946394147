import { PromptTemplate } from "../common/common_db";
import './TemplatesSidebar.css';

const TemplatesSidebar = ({ 
    templates,
    selectedTemplate,
    onSelectTemplate,
    templatesType,
    onTemplateTypeChange
  }: {
    templates: PromptTemplate[],
    selectedTemplate: PromptTemplate | null,
    onSelectTemplate: (template: PromptTemplate | null) => void,
    templatesType: 'personal' | 'public',
    onTemplateTypeChange: (v: 'personal' | 'public') => void
  }) => {
    return (
      <div className="templates-sidebar">
        <h2 className="templates-header">Templates</h2>
        
        <div 
          className={`custom-prompt-item ${selectedTemplate === null ? 'selected' : ''}`}
          onClick={() => onSelectTemplate(null)}
        >
          <h3>Custom Prompt</h3>
          <p>Create a new custom prompt</p>
        </div>

        <div className="template-type-buttons">
          <button 
            className={`template-type-button ${templatesType === 'personal' ? 'active' : ''}`}
            onClick={() => onTemplateTypeChange('personal')}>
              My Templates
          </button>
          <button 
            className={`template-type-button ${templatesType === 'public' ? 'active' : ''}`}
            onClick={() => onTemplateTypeChange('public')}>
              Public Templates
          </button>
        </div>
        
        <div className="templates-list">
          {templates.map(template => (
            <div 
              key={template.id}
              className={`template-item ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
              onClick={() => onSelectTemplate(template)}
            >
              <h3>{template.name}</h3>
              <p className="template-preview">
                {template.templateText.slice(0, 100)}
                {template.templateText.length > 100 ? '...' : ''}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default TemplatesSidebar;