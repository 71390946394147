import { Conversation, LinearTurnsTranscript, TemplatedPrompt, Transcription, TranscriptUnit} from './common_db';

export function formatUD(ud: number): string {
  return '$' + (ud / 1000 / 1000).toFixed(2);
}

export function transcriptionStoragePath(conversationID: string, transcriptionID: string): string {
  return `transcriptions/${conversationID}/${transcriptionID}.json`;
}

export function transcriptToTurns(transcription: Transcription): LinearTurnsTranscript {
  const speakerSegments: TranscriptUnit[][] = []
  let curSegment: TranscriptUnit[] = []
  for (const unit of transcription.transcript) {
    if (curSegment.length === 0 || curSegment[curSegment.length - 1].speaker === unit.speaker) {
      curSegment.push(unit);
    } else {
      speakerSegments.push(curSegment);
      curSegment = [unit];
    }
  }
  speakerSegments.push(curSegment);

  const formattedSegments = speakerSegments.map((segment) => {
    return {
      speaker: segment[0].speaker,
      start: segment[0].start,
      end: segment[segment.length - 1].end,
      contents: segment
    }
  })

  const {transcript, ...rest} = transcription;
  return {...rest, turns: formattedSegments};
}

export function textFromLinearTurnsTranscript(transcript: LinearTurnsTranscript): string {
  return transcript.turns.map(turn => `${turn.speaker}\n${turn.contents.map(unit => unit.word).join(' ')}`).join('\n');
}

export async function textFromTemplatedPrompt(
  templatedPrompt: TemplatedPrompt, 
  get: (convo: Conversation) => Promise<Transcription | null>
): Promise<string> {
  let placeholderCount = (templatedPrompt.template.templateText.match(/\$\{[^}]*\}/g) || []).length;
  if (placeholderCount !== templatedPrompt.values.length) {
    throw new Error(`Expected ${placeholderCount} values but got ${templatedPrompt.values.length}`);
  }

  const values = await Promise.all(
    templatedPrompt.values.map(async (value) => {
      if (typeof value === 'string') {
        return value;
      }
      const transcription = await get(value);
      if (!transcription) {
        throw new Error(`Transcription not found for conversation ${value.id}`);
      }
      return textFromLinearTurnsTranscript(transcriptToTurns(transcription))
    }) 
  )

  let result = templatedPrompt.template.templateText;
  values.forEach((value) => {
    result = result.replace(/\$\{[^}]*\}/, value);
  });


  return result;
}