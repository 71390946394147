import { buildCreateSlice, asyncThunkCreator, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationGroup, Annotation, AnnotationGroupDBValue } from '../../common/transcripts'
import { AnnotationPromptDebugValue } from '../../common/common_db';
import { RootState } from '../../store';
import { FireLoomStore } from '../../db';
import uuid4 from 'uuid4';

interface CreateAnnotationPayload {
  loomStore: FireLoomStore;
  annotation: Omit<Annotation, 'groupID'>;
}

interface DeleteAnnotationPayload {
  loomStore: FireLoomStore;
  annotationID: string;
  groupID: string;
}

interface DeleteAnnotationGroupPayload {
  loomStore: FireLoomStore;
  groupID: string;
}

interface UpdateAnnotationPayload {
  loomStore: FireLoomStore;
  annotationID: string;
  groupID: string;
  updates: Partial<Annotation>;
}

interface UpdateAnnotationGroupPayload {
  loomStore: FireLoomStore;
  groupID: string;
  updates: Partial<AnnotationGroupDBValue>;
}

type AnnotationPanelView = 'groups' | 'group-detail' | 'annotation-detail';

interface AnnotationFilter {
  showHumanOnly: boolean;
  showLLMOnly: boolean;
  tags: string[];
  dateRange?: { start: number; end: number };
  authors: string[];
}

interface AnnotationPanelState {
  view: AnnotationPanelView;
  currentGroupID: string | undefined;
  currentAnnotationID: string | undefined;
  filter: AnnotationFilter;
  workingGroupID: string | undefined;
  groups: AnnotationGroup[];
  navigationStack: Array<{
    view: AnnotationPanelView;
    groupID?: string;
    annotationID?: string;
  }>;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialAnnotationFilter: AnnotationFilter = {
  showHumanOnly: false,
  showLLMOnly: false,
  tags: [],
  authors: []
};

const initialState: AnnotationPanelState = {
  view: 'groups',
  currentGroupID: undefined,
  currentAnnotationID: undefined,
  filter: initialAnnotationFilter,
  workingGroupID: undefined,
  groups: [],
  navigationStack: [],
  status: 'idle',
  error: null
};

const createSliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const annotationPanelSlice = createSliceWithThunks({
  name: 'annotationPanel',
  initialState,
  reducers: (create) => ({
    // Regular actions
    setView: create.reducer((state, action: PayloadAction<{
      view: AnnotationPanelView;
      groupID?: string;
      annotationID?: string;
    }>) => {
      const { view, groupID, annotationID } = action.payload;
      state.navigationStack.push({
        view: state.view,
        groupID: state.currentGroupID,
        annotationID: state.currentAnnotationID
      });
      state.view = view;
      state.currentGroupID = groupID;
      state.currentAnnotationID = annotationID;
    }),

    navigateBack: create.reducer((state) => {
      const previous = state.navigationStack.pop();
      if (previous) {
        state.view = previous.view;
        state.currentGroupID = previous.groupID;
        state.currentAnnotationID = previous.annotationID;
      }
    }),

    setWorkingGroup: create.reducer((state, action: PayloadAction<string | undefined>) => {
      state.workingGroupID = action.payload;
    }),


    updateFilter: create.reducer((state, action: PayloadAction<Partial<AnnotationFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    }),

    setGroups: create.reducer((state, action: PayloadAction<AnnotationGroup[]>) => {
      console.log('Setting groups:', action.payload);
      state.groups = action.payload;
    }),

    updateGroup: create.reducer((state, action: PayloadAction<{
      groupID: string;
      updates: AnnotationGroup;  // Note: expecting full AnnotationGroup now
    }>) => {
      const index = state.groups.findIndex(g => g.id === action.payload.groupID);
      if (index !== -1) {
        // Replace the entire group with the updated version
        state.groups = [
          ...state.groups.slice(0, index),
          action.payload.updates,
          ...state.groups.slice(index + 1)
        ];
      }
    }),

    clearError: create.reducer((state) => {
      state.error = null;
      state.status = 'idle';
    }),

    // Thunks
    createAnnotation: create.asyncThunk<{ groupID: string; annotationID: string }, CreateAnnotationPayload>(
      async ({ loomStore, annotation }: {
        loomStore: FireLoomStore;
        annotation: Omit<Annotation, 'groupID'>;
      }, { getState, dispatch }) => {
        const state = getState() as RootState;
        let finalGroupID: string;
        const workingGroupID = state.annotationPanel.workingGroupID;

        if (!workingGroupID) {
          // Create new group first
          const groupID = uuid4()
          finalGroupID = groupID;
          const newGroup = {
            name: `Annotation Group ${new Date().toLocaleString()}`,
            annotationIDs: [],
            tags: [],
            creationTime: Date.now(),
            madeByHuman: true,
            authorID: annotation.authorID,
            conversationID: annotation.conversationID,
            id: groupID
          };

          await loomStore.createAnnotationGroup(newGroup);
          const annotationWithGroup = { ...annotation, groupID };
          await loomStore.createAnnotation(annotationWithGroup);
          
          await loomStore.updateAnnotationGroup(groupID, {
            annotationIDs: [annotation.id]
          });

          const completeGroup = await loomStore.getAnnotationGroup(groupID);
          if (!completeGroup) throw new Error('Failed to fetch created group');

          const currentGroups = state.annotationPanel.groups;
          dispatch(annotationPanelSlice.actions.setGroups([...currentGroups, completeGroup]));
          dispatch(annotationPanelSlice.actions.setWorkingGroup(groupID));

        } else {
          finalGroupID = workingGroupID;
          const annotationWithGroup = { ...annotation, groupID: workingGroupID };
          await loomStore.createAnnotation(annotationWithGroup);

          const currentGroup = await loomStore.getAnnotationGroupDBValue(workingGroupID);
          if (!currentGroup) throw new Error('Working group not found');
          
          await loomStore.updateAnnotationGroup(workingGroupID, {
            annotationIDs: [...currentGroup.annotationIDs, annotationWithGroup.id]
          });

          const updatedGroup = await loomStore.getAnnotationGroup(workingGroupID);
          if (!updatedGroup) throw new Error('Failed to fetch updated group');

          dispatch(annotationPanelSlice.actions.updateGroup({ 
            groupID: workingGroupID, 
            updates: updatedGroup 
          }));
        }
        return { groupID: finalGroupID, annotationID: annotation.id };
      },
      {
        pending: (state) => {
          state.status = 'loading';
          state.error = null;
        },
        fulfilled: (state) => {
          state.status = 'idle';
        },
        rejected: (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to create annotation';
        }
      }
    ),

    deleteAnnotation: create.asyncThunk<void, DeleteAnnotationPayload>(
      async ({ loomStore, annotationID, groupID }, { dispatch, getState }) => {

        const {deletedGroup} = await loomStore.deleteAnnotation(annotationID);
    
        if (deletedGroup) {
          const state = getState() as RootState;
          const updatedGroups = state.annotationPanel.groups.filter(g => g.id !== groupID);
          dispatch(annotationPanelSlice.actions.setGroups(updatedGroups));
          if (state.annotationPanel.workingGroupID === groupID) {
            dispatch(annotationPanelSlice.actions.setWorkingGroup(undefined));
          }
        } else {
          const updatedGroup = await loomStore.getAnnotationGroup(groupID);
          if (!updatedGroup) throw new Error('Failed to fetch updated group');
          dispatch(annotationPanelSlice.actions.updateGroup({ 
            groupID, 
            updates: updatedGroup 
          }));
        }
      },
      {
        pending: (state) => {
          state.status = 'loading';
          state.error = null;
        },
        fulfilled: (state, action) => {
          state.status = 'idle';
          state.view = 'groups';
          state.currentAnnotationID = undefined;
          // Clean up navigation stack
          state.navigationStack = []
          
        },
        rejected: (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to delete annotation';
        }
      }
    ),

    deleteAnnotationGroup: create.asyncThunk<void, DeleteAnnotationGroupPayload>(
      async ({ loomStore, groupID }: {
        loomStore: FireLoomStore;
        groupID: string;
      }, { getState, dispatch }) => {
        await loomStore.deleteAnnotationGroup(groupID);
        
        const state = getState() as RootState;
        const updatedGroups = state.annotationPanel.groups.filter(g => g.id !== groupID);
        dispatch(annotationPanelSlice.actions.setGroups(updatedGroups));
      },
      {
        pending: (state) => {
          state.status = 'loading';
          state.error = null;
        },
        fulfilled: (state, action) => {
          state.status = 'idle';
          if (state.currentGroupID === action.meta.arg.groupID) {
            state.view = 'groups';
            state.currentGroupID = undefined;
            state.currentAnnotationID = undefined;
            state.navigationStack = [];
          }
          if (state.workingGroupID === action.meta.arg.groupID) {
            state.workingGroupID = undefined;
          }
        },
        rejected: (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to delete annotation group';
        }
      }
    ),

    updateAnnotation: create.asyncThunk<void, UpdateAnnotationPayload>(
      async ({ loomStore, annotationID, groupID, updates }: {
        loomStore: FireLoomStore;
        annotationID: string;
        groupID: string;
        updates: Partial<Annotation>;
      }, { dispatch }) => {
        await loomStore.updateAnnotation(annotationID, updates);

        const updatedGroup = await loomStore.getAnnotationGroup(groupID);
        if (!updatedGroup) throw new Error('Failed to fetch updated group');

        dispatch(annotationPanelSlice.actions.updateGroup({ 
          groupID, 
          updates: updatedGroup 
        }));
      },
      {
        pending: (state) => {
          state.status = 'loading';
          state.error = null;
        },
        fulfilled: (state) => {
          state.status = 'idle';
        },
        rejected: (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to update annotation';
        }
      }
    ),

    updateAnnotationGroup: create.asyncThunk<void, UpdateAnnotationGroupPayload>(
      async ({ loomStore, groupID, updates }: {
        loomStore: FireLoomStore;
        groupID: string;
        updates: Partial<AnnotationGroupDBValue>;
      }, { dispatch }) => {
        await loomStore.updateAnnotationGroup(groupID, updates);

        const updatedGroup = await loomStore.getAnnotationGroup(groupID);
        if (!updatedGroup) throw new Error('Failed to fetch updated group');

        dispatch(annotationPanelSlice.actions.updateGroup({ 
          groupID, 
          updates: updatedGroup 
        }));
      },
      {
        pending: (state) => {
          state.status = 'loading';
          state.error = null;
        },
        fulfilled: (state) => {
          state.status = 'idle';
        },
        rejected: (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to update annotation group';
        }
      }
    ),
  })
});

// Basic selectors
export const selectAnnotationView = (state: RootState) => state.annotationPanel.view;
export const selectGroups = (state: RootState) => state.annotationPanel.groups;
export const selectCurrentGroupID = (state: RootState) => state.annotationPanel.currentGroupID;
export const selectCurrentAnnotationID = (state: RootState) => state.annotationPanel.currentAnnotationID;
export const selectFilter = (state: RootState) => state.annotationPanel.filter;
export const selectWorkingGroupID = (state: RootState) => state.annotationPanel.workingGroupID;
export const selectAnnotationPanelStatus = (state: RootState) => state.annotationPanel.status;
export const selectAnnotationPanelError = (state: RootState) => state.annotationPanel.error;

// Memoized selectors
export const selectCurrentGroup = createSelector(
  [selectGroups, selectCurrentGroupID],
  (groups, currentGroupID): AnnotationGroup | undefined => 
    groups.find(g => g.id === currentGroupID)
);

export const selectCurrentAnnotation = createSelector(
  [selectCurrentGroup, selectCurrentAnnotationID],
  (currentGroup, currentAnnotationID): Annotation | undefined => 
    currentGroup?.annotations.find(a => a.id === currentAnnotationID)
);

export const selectWorkingGroup = createSelector(
  [selectGroups, selectWorkingGroupID],
  (groups, workingGroupID): AnnotationGroup | undefined => 
    groups.find(g => g.id === workingGroupID)
);

export const selectFilteredGroups = createSelector(
  [selectGroups, selectFilter],
  (groups, filter): AnnotationGroup[] => {
    return groups.filter(group => {
      if (filter.showHumanOnly && !group.madeByHuman) return false;
      if (filter.showLLMOnly && group.madeByHuman) return false;
      if (filter.tags.length && !filter.tags.some(tag => group.tags.includes(tag))) return false;
      if (filter.authors.length && !filter.authors.includes(group.authorID)) return false;
      if (filter.dateRange) {
        const { start, end } = filter.dateRange;
        if (group.creationTime < start || group.creationTime > end) return false;
      }
      return true;
    });
  }
);

// New selector to get all annotations from filtered groups
export const selectFilteredAnnotations = createSelector(
  [selectFilteredGroups],
  (filteredGroups): Annotation[] => {
    return filteredGroups.reduce<Annotation[]>((allAnnotations, group) => {
      return [...allAnnotations, ...group.annotations];
    }, []);
  }
);

export const {
  setView,
  navigateBack,
  setWorkingGroup,
  updateFilter,
  setGroups,
  updateGroup,
  clearError,
  createAnnotation,
  deleteAnnotation,
  deleteAnnotationGroup,
  updateAnnotation,
  updateAnnotationGroup,
} = annotationPanelSlice.actions;

export default annotationPanelSlice.reducer;