import React, { useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import './DeleteButton.css';

interface DeleteButtonProps {
  onDelete: () => void;
  itemType: string;
  className?: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete, itemType, className = '' }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <>
      <button
        className={`delete-button ${className}`}
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up
          setShowConfirmDialog(true);
        }}
      >
        Delete
      </button>

      <ConfirmationDialog
        isOpen={showConfirmDialog}
        title={`Delete ${itemType}?`}
        message={`Are you sure you want to delete this ${itemType}? This action cannot be undone.`}
        onConfirm={() => {
          onDelete();
          setShowConfirmDialog(false);
        }}
        onCancel={() => setShowConfirmDialog(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default DeleteButton;