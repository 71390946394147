
import React from 'react';
import './Tooltip.css';


function Tooltip({text}: {text: string}) {
  return (

    <span className="tooltip">
      <span className="question-mark">?</span>
      <span className="tooltiptext">{text}</span>
    </span>
  );
}

export default Tooltip;
