import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  Conversation, 
  LinearTurnsTranscript, 
  ConvoVisibility
} from '../common/common_db';
import { Annotation } from '../common/transcripts';
import { RootState } from '../store';

type TranscriptTabType = 'query' | 'annotations';

interface TranscriptViewerState {
  conversation: Conversation | null;
  transcript: LinearTurnsTranscript | null;
  loading: boolean;
  error: string | null;
  rangeStart: number;
  rangeEnd: number;
  userID: string | null;
  activeTab: TranscriptTabType;
}

const initialState: TranscriptViewerState = {
  conversation: null,
  transcript: null,
  loading: true,
  error: null,
  rangeStart: 0,
  rangeEnd: 0,
  userID: null,
  activeTab: 'annotations',
};

const transcriptViewerSlice = createSlice({
  name: 'transcriptViewer',
  initialState,
  reducers: {
    setConversation: (state, action: PayloadAction<Conversation>) => {
      state.conversation = action.payload;
    },
    setTranscript: (state, action: PayloadAction<LinearTurnsTranscript>) => {
      state.transcript = action.payload;
      state.rangeEnd = action.payload.turns.length - 1;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setRange: (state, action: PayloadAction<{ start: number; end: number }>) => {
      state.rangeStart = action.payload.start;
      state.rangeEnd = action.payload.end;
    },
    setUserID: (state, action: PayloadAction<string | null>) => {
      state.userID = action.payload;
    },
    updateVisibility: (state, action: PayloadAction<ConvoVisibility>) => {
      if (state.conversation) {
        state.conversation.visibility = action.payload;
      }
    },
    setActiveTab: (state, action: PayloadAction<TranscriptTabType>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setConversation,
  setTranscript,
  setLoading,
  setError,
  setRange,
  setUserID,
  updateVisibility,
  setActiveTab,
} = transcriptViewerSlice.actions;

// Selectors
export const selectConversation = (state: RootState) => state.transcriptViewer.conversation;
export const selectTranscript = (state: RootState) => state.transcriptViewer.transcript;
export const selectLoading = (state: RootState) => state.transcriptViewer.loading;
export const selectError = (state: RootState) => state.transcriptViewer.error;
export const selectRange = (state: RootState) => ({
  start: state.transcriptViewer.rangeStart,
  end: state.transcriptViewer.rangeEnd,
});
export const selectUserID = (state: RootState) => state.transcriptViewer.userID;
export const selectActiveTab = (state: RootState) => state.transcriptViewer.activeTab;

export default transcriptViewerSlice.reducer;