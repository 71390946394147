import {httpsCallable} from 'firebase/functions';
import {firebaseFunctions, getCurrentUser} from './firebase_config';
import {ModelType} from './common/llm_clients';
import {Conversation, DiscordInfoValue, UserValue} from './common/common_db';

export async function callGetOrCreateUser() : Promise<UserValue | null> {

  const authUser = await getCurrentUser();
  if (!authUser) {
    return null;
  }

  // Initialize the callable function
  const getOrCreateUser = httpsCallable(firebaseFunctions, 'getOrCreateUser');

  var response;
  try {
    // Call the function with an empty object
    response = await getOrCreateUser({});
  } catch (error: any) {
    throw new Error(`Error fetching user: ${error.message || 'Unknown error'}`);
  }

  const data: any = response.data || {error: 'No data returned from server'};

  const err = data.error;

  if (err) {
    throw new Error(`Error from the server: ${err}`);
  }

  // Extract the user from the response
  const user = data.user;

  if (!user) {
    throw new Error('No user returned from the server');
  }

  return user;
}

export async function callCompletions(
  body : {prompt: string, max_tokens: number, n: number, temperature: number, model: ModelType}
) : Promise<string[]> {

  const authUser = await getCurrentUser();
  if (!authUser) {
    throw new Error('Not signed in');
  }

  // Initialize the callable function
  const completions2 = httpsCallable(firebaseFunctions, 'completions', {timeout: 120000});

  var response;
  try {
  // Call the function with the body object
    response = await completions2(body);
  } catch (error: any) {
    console.dir(error);
    if (error.message == 'internal') {
      throw new Error("Error fetching completions. Probably, it's a hyperbolic.xyz timeout error. Please try again later.");
    } else {
      throw new Error(`Error fetching completions: ${error.message || 'Unknown error'}`);
    }
  }

  const data: any = response.data || {error: 'No data returned from server'};

  const err = data.error;

  if (err) {
    throw new Error(`Error from the server: ${err}`);
  }

  // Extract the completions from the response
  const completions = data.completions;

  if (!completions) {
    throw new Error('No completions returned from the server');
  }

  return completions;
}

export async function callCreditsCheckoutSession(
  body: {cents: number, successUrl: string, cancelUrl: string}
) : Promise<{sessionId: string}> {

  const creditsCheckoutSession = httpsCallable(firebaseFunctions, 'creditsCheckoutSession');

  var response;
  try {
    response = await creditsCheckoutSession(body);
  } catch (error: any) {
    throw new Error(`Error creating checkout session: ${error.message || 'Unknown error'}`);
  }

  const data: any = response.data || {error: 'No data returned from server'};

  const err = data.error;

  if (err) {
    throw new Error(`Error from the server: ${err}`);
  }

  const sessionId = data.sessionId;

  if (!sessionId) {
    throw new Error('No sessionId returned from the server');
  }

  return {sessionId};
}

export async function callTranscribeMedia(conversation: Conversation): Promise<void> {
  const authUser = await getCurrentUser();
  if (!authUser) {
    throw new Error('Not signed in');
  }

  const transcribe = httpsCallable(firebaseFunctions, 'transcribeMedia', {timeout: 600000});
  try {
    await transcribe({conversation: conversation});
  } catch (error: any) {
    throw new Error(`Error transcribing media: ${error.message || 'Unknown error'}`);
  }
}

export async function callHandleDiscordAuth(code: string, redirectUri: string): Promise<DiscordInfoValue> {
  const authUser = await getCurrentUser();
  if (!authUser) {
    throw new Error('Not signed in');
  }
  const handleDiscordAuth = httpsCallable(firebaseFunctions, 'handleDiscordAuth');
  const response = await handleDiscordAuth({ code, redirectUri });
  const data: any = response.data || {error: 'No data returned from server'};
  if (!data.discordUser) {
    throw new Error('No Discord user returned from the server');
  }
  return data.discordUser as DiscordInfoValue
}

export async function callHandleDiscordLinkUpdate(discordId: string, action: 'link' | 'unlink'): Promise<void> {
  console.log("in callable, discordId:", discordId, "action:", action);
  const authUser = await getCurrentUser();
  if (!authUser) {
    throw new Error('Not signed in');
  }
  const handleDiscordLinkUpdate = httpsCallable(firebaseFunctions, 'handleDiscordLinkUpdate');
  try {
    await handleDiscordLinkUpdate({ discordId, action });
  } catch (error: any) {
    console.error('Full error object:', error);
    console.error('Error details:', {
      code: error.code,
      message: error.message,
      details: error.details,
      stack: error.stack
    });
    throw new Error(`Error updating Discord link: ${error.message || 'Unknown error'}`);
  }
}
