import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectAnnotationView, 
  selectFilteredGroups,
  selectCurrentGroup,
  selectCurrentAnnotation,
  selectFilter,
  updateFilter,
  setView,
  deleteAnnotationGroup,
  deleteAnnotation,
  selectWorkingGroup,
  setWorkingGroup
} from './annotationPanelSlice';
import { AnnotationGroup } from '../../common/transcripts';
import { RootState } from '../../store';
import DeleteButton from '../DeleteButton';
import AnnotationDetailView from './AnnotationDetailView';
import { useAppDispatch, useFireLoomStore } from '../../hooks';
import './AnnotationPanel.css';
import { AnnotationDebugValueProvider, useAnnotationDebugValue } from '../../contexts/AnnotationDebuValueContext';
import AnnotationtPromptDebugView from './AnnotationPromptDebugView';
import CollapsibleSplitPanel from '../CollapsibleSplitPanel';

const WorkingGroupIndicator: React.FC = () => {
  const workingGroup = useSelector(selectWorkingGroup);
  const dispatch = useAppDispatch();

  if (!workingGroup) return null;

  return (
    <div className="working-group-indicator">
      <span>Working Group: </span>
      <button
        className="working-group-button"
        onClick={() => dispatch(setView({ 
          view: 'group-detail',
          groupID: workingGroup.id
        }))}
      >
        {workingGroup.name}
      </button>
    </div>
  );
};

const GroupDetailView: React.FC<{ group: AnnotationGroup }> = ({ group }) => {
  const dispatch = useAppDispatch();
  const loomStore = useFireLoomStore();
  const workingGroup = useSelector(selectWorkingGroup);
  const isWorkingGroup = workingGroup?.id === group.id;
  const annoPromptDebugValue = useAnnotationDebugValue()
  console.log(annoPromptDebugValue)
  
  const handleDeleteGroup = async () => {
    try {
      console.log(`Deleting group ${group.id}`);
      await dispatch(deleteAnnotationGroup({ loomStore, groupID: group.id }));
      dispatch(setView({ view: 'groups' }));
    } catch (error) {
      console.error('Failed to delete group:', error);
    }
  };

  const toggleWorkingGroup = () => {
    dispatch(setWorkingGroup(isWorkingGroup ? undefined : group.id));
  };

  return (
    <div className="group-detail-view">
      <div className="group-detail-header">
        <h3>{group.name}</h3>
        <div className="group-detail-actions">
          {group.madeByHuman && (
            <button
              className={`working-group-toggle ${isWorkingGroup ? 'active' : ''}`}
              onClick={toggleWorkingGroup}>
              {isWorkingGroup ? 'Unset Working Group' : 'Set as Working Group'}
            </button>
          )}
          <DeleteButton
            itemType="group"
            onDelete={handleDeleteGroup}
            className="group-delete-button"
          />
        </div>
      </div>
      {!group.madeByHuman && annoPromptDebugValue.debugValue && (
        <AnnotationtPromptDebugView debug={annoPromptDebugValue.debugValue} />
      )}
    </div>
  );
};

const NavigationBreadcrumb: React.FC = () => {
  const currentGroup = useSelector(selectCurrentGroup);
  const currentAnnotation = useSelector(selectCurrentAnnotation);
  const dispatch = useAppDispatch();

  return (
    <div className="annotation-breadcrumb">
      <button 
        className="breadcrumb-item"
        onClick={() => dispatch(setView({ view: 'groups' }))}
      >
        Groups
      </button>
      {currentGroup && (
        <>
          <span className="breadcrumb-separator">/</span>
          <button 
            className="breadcrumb-item"
            onClick={() => dispatch(setView({ 
              view: 'group-detail',
              groupID: currentGroup.id
            }))}
          >
            {currentGroup.name}
          </button>
        </>
      )}
      {currentAnnotation && (
        <>
          <span className="breadcrumb-separator">/</span>
          <span className="breadcrumb-item current">
            Annotation {currentAnnotation.id.slice(0, 8) + '...'}
          </span>
        </>
      )}
    </div>
  );
};

const FilterPanel: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);

  return (
    <div className="filter-panel">
      <h3>Filters</h3>
      <div className="filter-section">
        <label className="filter-checkbox">
          <input
            type="checkbox"
            checked={filter.showHumanOnly}
            onChange={(e) => dispatch(updateFilter({ showHumanOnly: e.target.checked }))}
          />
          Show Human Annotations
        </label>
        <label className="filter-checkbox">
          <input
            type="checkbox"
            checked={filter.showLLMOnly}
            onChange={(e) => dispatch(updateFilter({ showLLMOnly: e.target.checked }))}
          />
          Show LLM Annotations
        </label>
      </div>
      {/* Add more filter controls */}
    </div>
  );
};

const GroupsList: React.FC = () => {
  const groups = useSelector(selectFilteredGroups);
  const dispatch = useAppDispatch()
  const loomStore = useFireLoomStore();

  const handleDeleteGroup = async (groupId: string) => {
    try {
      // TODO: Implement actual deletion logic here
      console.log(`Deleting group ${groupId}`);
      await dispatch(deleteAnnotationGroup({ loomStore, groupID: groupId }));
    } catch (error) {
      console.error('Failed to delete group:', error);
    }
  };

  return (
    <div className="groups-list">
      {groups.length > 0 ? 
        groups.map(group => (
          <div 
            key={group.id}
            className="group-item"
            onClick={() => dispatch(setView({ 
              view: 'group-detail',
              groupID: group.id 
            }))}
          >
            <div className="group-item-header">
              <h4>
                {group.name}
              </h4>
              <DeleteButton
                itemType="group"
                onDelete={() => handleDeleteGroup(group.id)}
                className="group-delete-button"
              />
            </div>
            <div className="group-meta">
              <span>{group.annotations.length} annotations</span>
              <span>{group.madeByHuman ? 'Human' : 'LLM'}</span>
            </div>
            <div className="group-tags">
              {group.tags.map(tag => (
                <span key={tag} className="tag">{tag}</span>
              ))}
            </div>
          </div>
        ))
      : <div className="empty-state">No annotation groups</div>}
    </div>
  );
};

const AnnotationsList: React.FC<{ groupID: string }> = ({ groupID }) => {
  const group = useSelector((state: RootState) => 
    state.annotationPanel.groups.find(g => g.id === groupID)
  );
  const dispatch = useAppDispatch();
  const loomStore = useFireLoomStore();

  const handleDeleteAnnotation = async (annotationId: string) => {
    try {
      // TODO: Implement actual deletion logic here
      console.log(`Deleting annotation ${annotationId} from group ${groupID}`);
      await dispatch(deleteAnnotation({loomStore, groupID, annotationID: annotationId }));
    } catch (error) {
      console.error('Failed to delete annotation:', error);
    }
  };

  if (!group) return null;

  return (
    <div className="annotations-list">
      {group.annotations.map(annotation => (
        <div 
          key={annotation.id}
          className="annotation-item"
          onClick={() => dispatch(setView({
            view: 'annotation-detail',
            groupID,
            annotationID: annotation.id
          }))}
        >
          <div className="annotation-item-header">
            <div 
              className="annotation-preview"
              
            >
              {annotation.startUnit.word} ... {annotation.endUnit.word}
            </div>
            {group.madeByHuman &&
              <DeleteButton
              itemType="annotation"
              onDelete={() => handleDeleteAnnotation(annotation.id)}
              className="annotation-delete-button"
            />}
          </div>
          <div className="annotation-meta">
            <span>Created: {new Date(annotation.creationTime).toLocaleDateString()}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const AnnotationPanel: React.FC = () => {
  const view = useSelector(selectAnnotationView);
  const currentGroup = useSelector(selectCurrentGroup);
  const currentAnnotation = useSelector(selectCurrentAnnotation);

  const renderSplitView = (leftContent: React.ReactNode, rightContent: React.ReactNode) => (
    <CollapsibleSplitPanel
      leftPanel={leftContent}
      rightPanel={rightContent}
      leftPanelWidth="50%"
      minLeftWidth="300px"
    />
  );

  return (
    <div className="annotation-panel">
      <NavigationBreadcrumb />
      <WorkingGroupIndicator />
      <div className="panel-content">
        {view === 'groups' && renderSplitView(
              <GroupsList />,
              <FilterPanel />
        )}
        {view === 'group-detail' && currentGroup && renderSplitView(
          <AnnotationsList groupID={currentGroup.id} />,
          <AnnotationDebugValueProvider>
            <GroupDetailView group={currentGroup} />
          </AnnotationDebugValueProvider>
        )}
        {view === 'annotation-detail' && currentAnnotation && (
          <AnnotationDetailView 
            annotation={currentAnnotation} 
            groupID={currentGroup?.id || ''} 
          />
        )}
      </div>
    </div>
  );
};