import { buildCreateSlice, asyncThunkCreator, PayloadAction } from '@reduxjs/toolkit';
import { MODEL_INFORMATION, ModelType } from '../../common/llm_clients';
import { chunkTranscriptForModel, formatAnnotationPrompt, parseAnnotations, 
  basicTurnFormatters, Annotation, AnnotationGroupDBValue, 
  stringifyRangable, AnnotationParseError,
  AnnotationParseErrorData} from '../../common/transcripts';
import type { AnnotationPromptDebugValue, TranscriptUnit } from '../../common/common_db';
import type { RootState } from '../../store';
import { FireLoomStore } from '../../db';
import { callCompletions } from '../../callables';
import { v4 as uuidv4 } from 'uuid';
import { setGroups, setView } from './annotationPanelSlice';
import { setActiveTab } from '../../pages/transcriptViewerSlice';



const formatError = (error: unknown): string | AnnotationParseErrorData => {
  console.log("formatError")
  console.log(error)
    if (error instanceof AnnotationParseError) {
      return {message: error.message, transcriptTextIndex: error.transcriptTextIndex, llmTextIndex: error.llmTextIndex};
    } else if (typeof error === 'string') {
      return error;
    } else {
      return JSON.stringify(error, null, 2);
    }
  };

interface QueryPanelState {
  systemPrompt: string;
  userPrompt: string;
  selectedModel: ModelType;
  selectedGroups: string[];
  isLoading: boolean;
  error: string | null;
}

interface MakeQueryPayload {
  loomStore: FireLoomStore;
}

const initialState: QueryPanelState = {
  systemPrompt: `You are an expert at analyzing conversations. Your task is to analyze the provided conversation transcript and create annotations as specified in the user's prompt. Your annotations should be precise and relevant to the analysis requested.`,
  userPrompt: '',
  selectedModel: 'gpt-4o-mini',
  selectedGroups: [],
  isLoading: false,
  error: null,
};

const createSliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const queryPanelSlice = createSliceWithThunks({
  name: 'queryPanel',
  initialState,
  reducers: (create) => ({
    setUserPrompt: create.reducer((state, action: PayloadAction<string>) => {
      state.userPrompt = action.payload;
    }),

    setSystemPrompt: create.reducer((state, action: PayloadAction<string>) => {
      state.systemPrompt = action.payload;
    }),

    setModel: create.reducer((state, action: PayloadAction<ModelType>) => {
      state.selectedModel = action.payload;
    }),

    toggleSelectedGroup: create.reducer((state, action: PayloadAction<string>) => {
      const groupId = action.payload;
      const index = state.selectedGroups.indexOf(groupId);
      if (index === -1) {
        state.selectedGroups.push(groupId);
      } else {
        state.selectedGroups.splice(index, 1);
      }
    }),

    clearError: create.reducer((state) => {
      state.error = null;
    }),

    makeQuery: create.asyncThunk(
      async ({ loomStore }: MakeQueryPayload, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { userPrompt, selectedModel, systemPrompt } = state.queryPanel;
        const transcript = state.transcriptViewer.transcript
        const userID = state.transcriptViewer.userID
        if (!transcript) {
          throw new Error('No transcript to query');
        }
        
        const chunks = chunkTranscriptForModel(transcript, selectedModel);
        const prompts = chunks.map((chunk) => formatAnnotationPrompt(chunk, userPrompt, systemPrompt));
        const llmTexts = await Promise.all(prompts.map((prompt) => callCompletions({
          prompt,
          max_tokens: MODEL_INFORMATION[selectedModel].maxOutputTokens,
          n: 1,
          temperature: 0.7,
          model: selectedModel,
        })));

        const debugId = uuidv4();
        const groupId = uuidv4();
        const results = llmTexts.map((llmText, i) => {
          const text = llmText[0];
          try {
            const parsedAnnos = parseAnnotations(text, chunks[i], basicTurnFormatters);
            const annotations: Annotation[] = parsedAnnos.map((parsedAnnotation) => ({
              ...parsedAnnotation,
              content: parsedAnnotation.attributes,
              id: uuidv4(),
              authorID: userID || '',
              conversationID: transcript.conversationID,
              creationTime: Date.now(),
              lastModifiedTime: Date.now(),
              tags: [],
              groupID: groupId,
              madeByHuman: false,
            }));
            
            return {
              transcriptChunkText: stringifyRangable(chunks[i], basicTurnFormatters),
              llmText: text,
              startUnit: chunks[i][0].contents[0],
              endUnit: chunks[i][chunks[i].length - 1].contents.at(-1) as TranscriptUnit, 
              annotationIDs: annotations.map(a => a.id),
              chunkPreviewText: chunks[i][0].contents.slice(0, 10).map(unit => unit.word).join(" ") + 
              '...' + 
              chunks[i][chunks[i].length - 1].contents.slice(-10).map(unit => unit.word).join(" "),
              annotations
            };
          } catch (e) {
            return {
              transcriptChunkText: stringifyRangable(chunks[i], basicTurnFormatters),
              llmText: text,
              startUnit: chunks[i][0].contents[0],
              endUnit: chunks[i][chunks[i].length - 1].contents.at(-1) as TranscriptUnit, //not sure best way to assert non emtpy...
              error: formatError(e),
              annotationIDs: [],
              chunkPreviewText: chunks[i][0].contents.slice(0, 10).map(unit => unit.word).join(" ") + 
              '...' + 
              chunks[i][chunks[i].length - 1].contents.slice(-10).map(unit => unit.word).join(" "),
            };
          }
        });

        const validResults = results.filter(r => !r.error);
        const validAnnotations = validResults.flatMap(r => r.annotations ? r.annotations : []);
        
        const annotationGroupDBVal: AnnotationGroupDBValue = {
          id: groupId,
          authorID: userID || '',
          name: `${selectedModel} Annotation: ${new Date(Date.now()).toLocaleString()}`,
          annotationIDs: validAnnotations.map(a => a.id),
          madeByHuman: false,
          creationTime: Date.now(),
          tags: [],
          conversationID: transcript.conversationID,
          promptDebugValueID: debugId,
        };

        const debugValue: AnnotationPromptDebugValue = {
          id: debugId,
          ownerID: userID || '',
          annotationGroupID: groupId,
          conversationID: transcript.conversationID,
          creationEpochTime: Date.now(),
          userPrompt,
          systemPrompt,
          modelID: selectedModel,
          results
        };

        await loomStore.createAnnotationGroup(annotationGroupDBVal)
        await loomStore.createAnnotationDebugValue(debugValue)
        
        if (validAnnotations.length > 0) {
          await Promise.all([...validAnnotations.map(annotation => loomStore.createAnnotation(annotation))])
        }

        const annotationGroup = {...annotationGroupDBVal, annotations: validAnnotations};
        dispatch(setGroups([...state.annotationPanel.groups, annotationGroup]));
        dispatch(setView({ view: 'group-detail', groupID: groupId}));
        dispatch(setActiveTab('annotations'));
      },
      {
        pending: (state) => {
          state.isLoading = true;
          state.error = null;
        },
        fulfilled: (state) => {
          state.isLoading = false;
        },
        rejected: (state, action) => {
          state.isLoading = false;
          state.error = action.error.message || 'Failed to make query';
        },
      }
    ),
  }),
});

// Selectors
export const selectSystemPrompt = (state: RootState) => state.queryPanel.systemPrompt;
export const selectUserPrompt = (state: RootState) => state.queryPanel.userPrompt;
export const selectSelectedGroups = (state: RootState) => state.queryPanel.selectedGroups;
export const selectIsLoading = (state: RootState) => state.queryPanel.isLoading;
export const selectError = (state: RootState) => state.queryPanel.error;
export const selectSelectedModel = (state: RootState) => state.queryPanel.selectedModel;

export const { 
  setUserPrompt, 
  setModel, 
  toggleSelectedGroup, 
  clearError,
  makeQuery,
  setSystemPrompt
} = queryPanelSlice.actions;

export default queryPanelSlice.reducer;