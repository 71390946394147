import React from 'react';
import './LoadingSpinner.css';

interface LoadingSpinnerProps {
  size?: 'tiny' | 'small' | 'medium' | 'large';
  overlay?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ 
  size = 'medium',
  overlay = false 
}) => {
  return (
    <div className={overlay ? 'loading-overlay' : ''}>
      <div className={`loading-spinner ${size}`}>
        <div className="spinner-circle"></div>
        <div className="spinner-circle"></div>
        <div className="spinner-circle"></div>
        <div className="spinner-circle"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
