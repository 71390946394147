import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, selectActiveTab } from '../../pages/transcriptViewerSlice';
import { AnnotationPanel } from './AnnotationPanel'
import { QueryPanel } from './QueryPanel';
import './SidePanel.css';


export type TranscriptTabType = 'query' | 'annotations';

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`tab ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

interface TabsProps {
  activeTab: TranscriptTabType;
  onTabChange: (tab: TranscriptTabType) => void;
}

export const Tabs: React.FC<TabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="tabs-container">
      <Tab 
        label="Query" 
        isActive={activeTab === 'query'} 
        onClick={() => onTabChange('query')} 
      />
      <Tab 
        label="Annotations" 
        isActive={activeTab === 'annotations'} 
        onClick={() => onTabChange('annotations')} 
      />
    </div>
  );
};

export const SidePanel: React.FC = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector(selectActiveTab);
  
    const handleTabChange = (tab: TranscriptTabType) => {
      dispatch(setActiveTab(tab));
    };
  
    return (
      <div className="side-panel">
        <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
        <div className="panel-content">
          {activeTab === 'query' ? (
            <QueryPanel />
          ) : (
            <AnnotationPanel/>
          )}
        </div>
      </div>
    );
  };

