export type OpenAIModelType = 'gpt-4o-mini' | 'gpt-4o' | 'o1-preview';
export type AnthropicModelType = 'claude-3-5-sonnet-20241022' | 'claude-3-5-haiku-20241022' | 'claude-3-haiku-20240307';
export type HyperbolicModelType = 'meta-llama/Meta-Llama-3.1-405B' | 'meta-llama/Meta-Llama-3.1-70B-Instruct';
export type ModelType = HyperbolicModelType | OpenAIModelType | AnthropicModelType;

// Costs are in microDollars / token
type ModelInformation = {
  inputTokenCost: number,
  outputTokenCost: number,
  maxOutputTokens: number,
  contextWindow: number
};

type ModelInformationMap = {
  [K in ModelType]: ModelInformation
}

export const MODEL_INFORMATION: ModelInformationMap = {
  'meta-llama/Meta-Llama-3.1-405B': {
    inputTokenCost: 4,
    outputTokenCost: 4,
    maxOutputTokens: 4096, //couldn't find a source, this is a guess
    contextWindow: 128000
  },
  'meta-llama/Meta-Llama-3.1-70B-Instruct': {
    inputTokenCost: 0.4,
    outputTokenCost: 0.4,
    maxOutputTokens: 4096, //couldn't find a source, this is a guess
    contextWindow: 128000
  },
  'claude-3-5-sonnet-20241022': {
    inputTokenCost: 3,
    outputTokenCost: 15,
    maxOutputTokens: 8192,
    contextWindow: 200000
  },
  'claude-3-5-haiku-20241022': {
    inputTokenCost: 1,
    outputTokenCost: 5,
    maxOutputTokens: 8192,
    contextWindow: 200000
  },
  'claude-3-haiku-20240307': {
    inputTokenCost: 0.25,
    outputTokenCost: 1.25,
    maxOutputTokens: 4096,
    contextWindow: 200000
  },
  'gpt-4o-mini': {
    inputTokenCost: 0.15,
    outputTokenCost: 0.6,
    maxOutputTokens: 4096,
    contextWindow: 128000
  },
  'gpt-4o': {
    inputTokenCost: 2.5,
    outputTokenCost: 10,
    maxOutputTokens: 4096,
    contextWindow: 128000
  },
  'o1-preview': {
    inputTokenCost: 15,
    outputTokenCost: 60,
    maxOutputTokens: 4096,
    contextWindow: 128000
  }
};

