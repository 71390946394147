
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {User} from 'firebase/auth';
import {QueryDocumentSnapshot, DocumentData} from 'firebase/firestore';
import {DocumentSummary} from '../common/common_db';
import {wait, FireLoomStore} from '../db';
import { firebaseAuth, getCurrentUser } from '../firebase_config';
import './Documents.css';

function Documents() {
  const [docStore, setDocStore] = useState(new FireLoomStore());
  const [documents, setDocuments] = useState<null | DocumentSummary[]>(null);
  const [newDocName, setNewDocName] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [startAfter, setStartAfter] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);


  function fetchDocuments() {
    docStore.getDocumentSummaries(startAfter).then((docs) => {
      console.log('docs', docs);
      console.log('prevDocs', documents);
      setDocuments(prevDocs => {
        const allDocs = [...(prevDocs || []), ...docs.summaries];
        return Array.from(
          new Map(allDocs.map(doc => [doc.id, doc]))
          .values()
        );
      });
      setStartAfter(docs.last);
    });
  }

  useEffect(() => {
    console.log("in useEffect");
    getCurrentUser().then((currUser) => {
      console.log('currUser', currUser);
      setUser(currUser);
      setLoading(false);
      fetchDocuments();
    })
  }, []);

  if (user == null && !loading) {
    return <Navigate to="/auth" />;
  }

  function handleCreateDocument(e: React.FormEvent) {
    e.preventDefault();
    var docName = newDocName.trim();
    if (docName === "") {
      return;
    }
    docStore.createDocument(docName).then((summary) => {
      setDocuments((docs) => [summary, ...(docs || [])]);
    });
    setNewDocName("");
  }

  function handleDeleteDocument(doc: DocumentSummary) {
    if (!window.confirm(`Are you sure you want to delete the document "${doc.name}"?`)) {
      return;
    }
    docStore.deleteDocument(doc.id).then(() => {
      setDocuments(null);
      setStartAfter(null);
      fetchDocuments();
    });
  }
  return <div style={{textAlign: "center"}}>
    <header>
      <h1 className="title">Loom</h1>
    </header>
    {documents === null ? <p>Loading...</p> : <div>
      <form>
        <input type="text" value={newDocName} onChange={(e) => setNewDocName(e.target.value)} />
        <button onClick={handleCreateDocument}>Create Document</button>
      </form>
      <ul className="documents-list">
        {documents.map((doc) =>
          <li key={doc.id}>
            <Link to={`/loom/${doc.id}`}>{doc.name}</Link>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteDocument(doc)}>
              🗑️
            </span>
          </li>)}
      </ul>
      {startAfter != null && (<button onClick={fetchDocuments}>Load More</button>)}
    </div>}
  </div>;
}

export default Documents;
