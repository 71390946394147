import React, { useState, useEffect } from 'react';
import { PromptTemplate, TemplatedPrompt, TemplateValue, Conversation } from '../common/common_db';
import './PromptTemplateForm.css';

type Props = {
  template: PromptTemplate;
  conversations: Conversation[];
  onSubmit: (templatedPrompt: TemplatedPrompt) => void;
  hideSubmit?: boolean
};

type SlotState = {
  type: 'freeform' | 'conversation';
  value: string;
  name: string;
};

const AlertCircleIcon = ({ size = 16, className = '' }) => (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={className}
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="8" x2="12" y2="12" />
      <line x1="12" y1="16" x2="12.01" y2="16" />
    </svg>
  );

const PromptTemplateForm = ({ template, conversations, onSubmit, hideSubmit }: Props) => {
  const [slots, setSlots] = useState<Map<string, SlotState>>(new Map());
  const [error, setError] = useState<string>('');
  const [isSelectOpen, setIsSelectOpen] = useState<string | null>(null);

  useEffect(() => {
    const newSlots = new Map<string, SlotState>();
    const regex = /\${([^}]+)}/g;
    let match;

    while ((match = regex.exec(template.templateText)) !== null) {
      const slotContent = match[1];
      const isConversation = slotContent === 'conversation';
      newSlots.set(match[0], {
        type: isConversation ? 'conversation' : 'freeform',
        value: '',
        name: slotContent
      });
    }

    setSlots(newSlots);
  }, [template.templateText]);

  const handleSlotChange = (slot: string, value: string) => {
    setSlots(new Map(slots.set(slot, { ...slots.get(slot)!, value })));
    setError('');
  };

  const handleSubmit = () => {
    const slotsArray = Array.from(slots.entries());
    const emptySlot = slotsArray.find(([_, state]) => !state.value);
    if (emptySlot) {
      setError(`Please fill in all template slots`);
      return;
    }

    const values: TemplateValue[] = Array.from(slots.values()).map(state => {
      if (state.type === 'conversation') {
        return conversations.find(c => c.id === state.value)!;
      }
      return state.value;
    });

    onSubmit({
      template,
      values
    });
  };

  const renderSelect = (slot: string, state: SlotState) => {
    const selectedConversation = conversations.find(c => c.id === state.value);

    return (
      <div className="select-input">
        <button
          className="select-trigger"
          onClick={() => setIsSelectOpen(isSelectOpen === slot ? null : slot)}
          type="button"
        >
          {selectedConversation?.name || 'Select conversation...'}
        </button>
        {isSelectOpen === slot && (
          <div className="select-content">
            {conversations.map((conversation) => (
              <div
                key={conversation.id}
                className="select-item"
                onClick={() => {
                  handleSlotChange(slot, conversation.id);
                  setIsSelectOpen(null);
                }}
              >
                {conversation.name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderSlotInput = (slot: string, state: SlotState) => {
    if (state.type === 'conversation') {
      return renderSelect(slot, state);
    }

    return (
      <input
        type="text"
        value={state.value}
        onChange={(e) => handleSlotChange(slot, e.target.value)}
        placeholder={state.name}
        className="text-input"
      />
    );
  };

  const renderTemplateText = () => {
    let lastIndex = 0;
    const elements: JSX.Element[] = [];
    const regex = /\${([^}]+)}/g;
    let match;

    while ((match = regex.exec(template.templateText)) !== null) {
      // Add text before the slot
      if (match.index > lastIndex) {
        elements.push(
          <span key={`text-${match.index}`}>
            {template.templateText.slice(lastIndex, match.index)}
          </span>
        );
      }

      // Add the slot input
      const slot = match[0];
      console.log(`match: ${match}`);
      const state = slots.get(slot);
      if (state) {
        elements.push(
          <span key={`slot-${match.index}`} style={{ display: 'inline-block', minWidth: '200px' }}>
            {renderSlotInput(slot, state)}
          </span>
        );
      }

      lastIndex = match.index + match[0].length;
    }

    // Add remaining text after last slot
    if (lastIndex < template.templateText.length) {
      elements.push(
        <span key={`text-end`}>
          {template.templateText.slice(lastIndex)}
        </span>
      );
    }

    return elements;
  };

  return (
    <div className="prompt-form">
      <div className="prompt-form-header">
        <h3 className="prompt-form-title">{template.name}</h3>
        <p className="prompt-form-description">Fill in the template slots below:</p>
      </div>

      <div className="template-text">
        {renderTemplateText()}
      </div>

      {error && (
        <div className="error-alert">
          <AlertCircleIcon className="error-icon" />
          <p className="error-message">{error}</p>
        </div>
      )}

      {hideSubmit ? "" :
        <button 
            onClick={handleSubmit}
            className="submit-button">
            Set Prompt
          </button>}
    </div>
  );
};

export default PromptTemplateForm;