import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';
import {MobileView} from 'react-device-detect';
import './SideMenu.css';

// components/CloseButton.tsx
interface CloseButtonProps {
    onClick: () => void;
  }
  
  export const CloseButton = ({ onClick }: CloseButtonProps) => (
    <button 
      className="close-button" 
      onClick={onClick}
      aria-label="Close menu"
    >
      ×
    </button>
  );
  
  // components/SideMenu.tsx

interface SideMenuProps {
isOpen: boolean;
user: User | null;
onClose: () => void;
}

export const SideMenu = ({ isOpen, user, onClose }: SideMenuProps) => (
<>
    {isOpen && <div className="overlay" onClick={onClose}></div>}
    <nav className={`side-menu ${isOpen ? 'open' : ''}`}>
    <CloseButton onClick={onClose} />
    <ul>
        <li><Link to="/about" onClick={onClose}>About</Link></li>
        {!user && <li><Link to="/auth" onClick={onClose} className="login-link">Login</Link></li>}
        {user && (
        <>
            <li><Link to="/credits" onClick={onClose}>Credits</Link></li>
            <li><Link to="/auth" onClick={onClose}>Profile</Link></li>
            <MobileView>
              <li><Link to="/documents">Loom</Link></li>
              <li><Link to="/conversations">Conversations</Link></li>
              <li><Link to="/templates">Templates</Link></li>
            </MobileView>
        </>
        )}
    </ul>
    </nav>
</>
);
