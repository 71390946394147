import React, { useState, Fragment, useCallback, useRef } from 'react';
import { 
  type LinearTurnsTranscript,
  type SpeakerTurn,
} from '../common/common_db';
import { FireLoomStore } from '../db';
import { Annotation, findUnitInRangable } from '../common/transcripts';
import uuid4 from 'uuid4';
import './TranscriptBody.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranscript, selectUserID, selectConversation, setActiveTab} from '../pages/transcriptViewerSlice';
import { createAnnotation, selectFilteredAnnotations, setView } from './transcriptSidePanel/annotationPanelSlice';
import { AppDispatch } from '../store';
import { set } from 'zod';
import { AudioPlayerHandle } from './AudioPlayer';

const useAppDispatch: () => AppDispatch = useDispatch;

export const useWordBoundarySelection = () => {
    const [selectionRange, setSelectionRange] = useState<SelectionRange | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const latestSelectionRef = useRef<{
      startContainer: Element | null,
      endContainer: Element | null
    } | null>(null);
   
    const mouseMoveHandler = useCallback((e: React.MouseEvent) => {
      // console.log('mouse move start')
      if (e.buttons !== 1) {
        return;
      }
   
      const selection = document.getSelection();
      if (!selection || selection.rangeCount === 0) {
        setSelectionRange(null);
        return;
      }
      if (selection.isCollapsed) {
        setSelectionRange(null);
        return;
      }
      // Store the latest selection
      const range = selection.getRangeAt(0);
      latestSelectionRef.current = {
        startContainer: range.startContainer.parentElement,
        endContainer: range.endContainer.parentElement
      };
   
      // // Only schedule a new frame if we don't have one pending
      // if (!animationFrameRef.current) {
      //   animationFrameRef.current = requestAnimationFrame(() => {
          // console.log('animation frame start')
          // Process the latest selection we've stored
          if (latestSelectionRef.current) {
            const { startContainer, endContainer } = latestSelectionRef.current;
   
            // Find nearest word-id spans by traversing siblings
            const findNearestWordId = (element: Element | null): string | null => {
              // First, ensure we're at the span level
              if (!element) return null;
              if (element.tagName !== 'SPAN') {
                // If we're in a text node or other element, get to its parent span
                const parentSpan = element.closest('span');
                if (!parentSpan) return null;
                element = parentSpan;
              }
   
              // Check current element
              const currentWordId = element.getAttribute('data-word-id');
              if (currentWordId) return currentWordId;
   
              // Function to check siblings in a direction
              const checkSiblingsInDirection = (
                startElem: Element,
                getNextSibling: (elem: Element) => Element | null
              ): string | null => {
                let current = getNextSibling(startElem);
                while (current) {
                  if (current.tagName === 'SPAN') {
                    const wordId = current.getAttribute('data-word-id');
                    if (wordId) return wordId;
                  }
                  current = getNextSibling(current);
                }
                return null;
              };
   
              // Check previous siblings first (for range start)
              const previousWordId = checkSiblingsInDirection(
                element,
                elem => elem.previousElementSibling
              );
              if (previousWordId) return previousWordId;
   
              // Then check next siblings (for range end)
              const nextWordId = checkSiblingsInDirection(
                element,
                elem => elem.nextElementSibling
              );
              if (nextWordId) return nextWordId;
   
              return null;
            };
   
            const startId = findNearestWordId(startContainer);
            const endId = findNearestWordId(endContainer);
            // console.log("check end")
            if (startId && endId) {
              // Ensure the IDs are in the correct order (start should be less than end)
              const startNum = parseInt(startId);
              const endNum = parseInt(endId);
              // console.log('setting selection range')
              // flushSync(() => {
                setSelectionRange({
                  startId: Math.min(startNum, endNum),
                  endId: Math.max(startNum, endNum)
                });
              // });
            }
          }
          // Clear the animation frame ref so we can schedule another one
          // animationFrameRef.current = null;
        // });
      // }
    }, []);
   
    const removeSelection = useCallback(() => {
      setSelectionRange(null);
      window.getSelection()?.removeAllRanges();
      latestSelectionRef.current = null;
    }, [])
   
    return { selectionRange, mouseMoveHandler, removeSelection };
   };

const calculateModalPosition = (x: number, y: number) => {
  const modalWidth = 200;
  const modalHeight = 60;
  const padding = 10;

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  let posX = x + scrollX;
  let posY = y + scrollY;

  if (posX + modalWidth + padding > viewportWidth + scrollX) {
    posX = viewportWidth + scrollX - modalWidth - padding;
  }
  if (posY + modalHeight + padding > viewportHeight + scrollY) {
    posY = posY - modalHeight - padding;
  }

  return { x: posX, y: posY };
};

const TranscriptTurn = ({ 
  turn,
  audioRef,
  annotations,
  selectionRange
}: { 
  turn: SpeakerTurn,
  audioRef: React.RefObject<AudioPlayerHandle>,
  annotations: Annotation[],
  selectionRange: SelectionRange | null
}) => {
  const dispatch = useAppDispatch();
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const isWordInSelectionRange = (wordId: number): boolean => {
    if (!selectionRange) return false;
    return wordId >= selectionRange.startId && wordId <= selectionRange.endId;
  };

  const getAnnotationForWord = (wordId: string) => {
    return annotations.find(annotation => {
      const startId = annotation.startUnit.id;
      const endId = annotation.endUnit.id;
      const currentId = parseInt(wordId);
      return currentId >= startId && currentId <= endId;
    });
  };

  return (
    <div className="transcript-turn">
      <div className="turn-header">
        <span className="speaker-name">{turn.speaker}</span>
        <span 
          className="timestamp"
          onClick={() => audioRef.current?.seekToTime(turn.start)}>
          {formatTime(turn.start)}</span>
      </div>
      <p className="turn-content">
        {turn.contents.map((unit, index) => {
          const annotation = getAnnotationForWord(unit.id.toString());
          const wsInRange = isWordInSelectionRange(unit.id) && 
                           index > 0 && 
                           isWordInSelectionRange(turn.contents[index - 1].id);
          const wordInRange = isWordInSelectionRange(unit.id);
          const onClick = () => {
            console.log('click')
            if (annotation) {
              dispatch(setView({ view: 'annotation-detail', groupID: annotation.groupID, annotationID: annotation.id }));
            }
          }
          return (
            <Fragment key={unit.id}>
              {index > 0 && (
                <span
                  onClick={onClick} 
                  data-neighbor-word-id={turn.contents[index - 1].id.toString()}
                  className={`space ${wsInRange 
                    ? 'highlighted-range' 
                    : annotation ? 'annotated-text' : ''}`}
                  style={!wsInRange && annotation ? { backgroundColor: annotation.content['color'] } : undefined}
                > </span>
              )}
              <span
                id={`word-${unit.id}`} 
                onClick={onClick}
                data-word-id={unit.id.toString()}
                className={`word ${wordInRange
                  ? 'highlighted-range' 
                  : annotation ? 'annotated-text' : ''}`}
                style={!wordInRange && annotation ? { backgroundColor: annotation.content['color'] } : undefined}
              >
                {unit.word}
              </span>
            </Fragment>
          );
        })}
      </p>
    </div>
  );
};

interface SelectionRange {
  startId: number;
  endId: number;
}

interface AnnotationModalProps {
  position: { x: number, y: number };
  selectionRange: SelectionRange;
  onSelect: (color: string, range: SelectionRange) => void;
  onClickOutside: () => void;
}

const AnnotationModal = ({
  position,
  selectionRange,
  onSelect,
  onClickOutside
}: AnnotationModalProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    
    const timeoutId = setTimeout(() => {
      document.addEventListener('mousedown', handleClickOutside);
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  const colors = ['#FFD700', '#98FB98', '#87CEFA', '#DDA0DD', '#F08080'];

  return (
    <div 
      ref={modalRef}
      className="annotation-modal"
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        zIndex: 1000,
      }}
    >
      <div className="color-options">
        {colors.map((color) => (
          <button
            key={color}
            onClick={() => onSelect(color, selectionRange)}
            className="color-option"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </div>
  );
};

interface TranscriptProps {
  audioPlayerRef: React.RefObject<AudioPlayerHandle>;
}

export const TranscriptBody: React.FC<TranscriptProps> = ({audioPlayerRef}) => {
  const dispatch = useAppDispatch();
  const [docStore] = useState(new FireLoomStore());
  const transcript = useSelector(selectTranscript)
  const annotations = useSelector(selectFilteredAnnotations)
  const conversation = useSelector(selectConversation)
  const userID = useSelector(selectUserID)
  const [modalPosition, setModalPosition] = useState<{ x: number, y: number } | null>(null);
  const { selectionRange, mouseMoveHandler, removeSelection } = useWordBoundarySelection();

  const handleMouseUp = useCallback((e: React.MouseEvent) => {
    const selection = document.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return;
    }
    if (!selection.isCollapsed) {
      const { x, y } = calculateModalPosition(e.clientX, e.clientY);
      setModalPosition({ x, y });
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const handleAnnotationSelect = useCallback(async (color: string, range: SelectionRange) => {
    if (!transcript) return;

    const newAnnotation: Omit<Annotation, 'groupID'> = {
      startUnit: findUnitInRangable(transcript.turns, range.startId),
      endUnit: findUnitInRangable(transcript.turns, range.endId),
      content: { color },
      id: uuid4(),
      authorID: userID || "",
      creationTime: Date.now(),
      lastModifiedTime: Date.now(),
      madeByHuman: true,
      conversationID: conversation?.id || "",
      tags: [],
    };
    setModalPosition(null);
    const res = await dispatch(createAnnotation(
        {loomStore: docStore, annotation: newAnnotation}
      )).unwrap();
    dispatch(setActiveTab('annotations'));
    dispatch(setView({ view: 'annotation-detail', groupID: res.groupID, annotationID: newAnnotation.id }));
    removeSelection();
  }, [transcript, userID, conversation, annotations, removeSelection]);

  return (
    <div 
      className="transcript-body" 
      onMouseUp={handleMouseUp} 
      onMouseMove={mouseMoveHandler}
    >
      {transcript ? transcript.turns.map((turn, index) => (
        <TranscriptTurn 
          key={index} 
          turn={turn}
          audioRef={audioPlayerRef}
          annotations={annotations}
          selectionRange={selectionRange}
        />
      )) : ""}
      {modalPosition && selectionRange && (
        <AnnotationModal
          position={modalPosition}
          selectionRange={selectionRange}
          onSelect={handleAnnotationSelect}
          onClickOutside={() => {
          setModalPosition(null)
          removeSelection()
          }}
        />
      )}
    </div>
  );
};