import React, { useState, useMemo } from 'react';

function EditableText(
  { disabled,
    value,
    dispValue,
    trySetValue,
    rows,
    cols,
    isPointer }: 
  { disabled?: boolean,
    value: string,
    dispValue?: string,
    trySetValue: (newValue: string) => Promise<void>,
    rows?: number,
    cols?: number,
    isPointer?: boolean}) {


  const [editing, setEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(value);
  const [saving, setSaving] = useState<boolean>(false); // Track saving state

  if (!dispValue) {
    dispValue = value;
  }
  if (!rows) {
    rows = 1;
  }

  // Start editing
  const handleStartEdit = () => {
    setEditValue(value); // Initialize the edit value with the current value
    setEditing(true);
  };

  // Handle input change
  const handleEditChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditValue(e.target.value);
  };

  // Handle saving the value asynchronously, catching errors
  const handleSave = async () => {
    setSaving(true); // Disable buttons and show loading state
    try {
      await trySetValue(editValue); // Try saving the new value
      setEditing(false); // Exit editing mode if save is successful
    } catch (error: any) {
      alert(`Failed to save: ${error.message || error}`); // Alert if error occurs
    } finally {
      setSaving(false); // Re-enable buttons after async call completes
    }
  };

  // Handle canceling the edit
  const handleCancel = () => {
    setEditValue(value); // Reset the edit value
    setEditing(false);   // Exit editing mode without saving
  };

  return !editing ? (
    <>
      <span {...(isPointer ? {style: {cursor: 'pointer'}} : {})}>{dispValue}</span>
      {!disabled && (<span style={{ cursor: 'pointer' }} title="Edit" onClick={handleStartEdit}>✏️</span>)}
    </>
  ) : (
    <span>
      <textarea className="edit-textarea"
                rows={rows}
                {...(cols ? {cols: cols} : {})}
                value={editValue}
                onChange={handleEditChange}
                {...(cols ? {} : {style: {width: '100%'}})}
                />
      <button onClick={handleSave} disabled={saving}>
        {saving ? 'Saving...' : 'Save'}
      </button>
      <button onClick={handleCancel} disabled={saving}>
        Cancel
      </button>
    </span>
  );
}

export default EditableText;
