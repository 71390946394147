
import React, { useState, useMemo, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {QueryDocumentSnapshot, DocumentData} from 'firebase/firestore';
import { UserValue } from '../common/common_db';
import { formatUD } from '../common/util';
import { getCurrentUser } from '../firebase_config';
import { FireLoomStore } from '../db';
import { callCreditsCheckoutSession, callGetOrCreateUser } from '../callables';
import { PurchaseValue } from '../common/common_db';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51QANK0Rvq04dC3VjNkmiZkZfZ89E5Ew47WHE6tjvz4mRj2xtmcImDGAwTjCLxssb0YHY9oixdqqaWvnn0HxOXTPh00HroAOHRz');

const Credits: React.FC = () => {
  const [customDollars, setCustomDollars] = useState(10); // Set default amount
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [userValue, setUserValue] = useState<UserValue | null>(null);
  const [purchaseValues, setPurchaseValues] = useState<PurchaseValue[] | null>(null);
  const [startAfter, setStartAfter] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const fireStore = useMemo(() => new FireLoomStore(), []);


  useEffect(() => {
    async function doSetUser() {
      const user = await callGetOrCreateUser();
      if (user) {
        setUserValue(user);
      }
    }
    doSetUser();
  }, []);


  function fetchPurchases() {
    fireStore.getPurchases(startAfter).then((purchases) => {
      setPurchaseValues((prevPurchases) => [...(prevPurchases || []), ...purchases.purchases]);
      setStartAfter(purchases.last);
    });
  }

  useEffect(() => {
    fetchPurchases();
  }, []);

  async function handlePurchase(cents: number) {
    const stripe = await stripePromise;  // Load Stripe.js
    if (stripe == null) {
      setPurchaseStatus('Error loading Stripe.js');
      return;
    }

    try {
      const user = await getCurrentUser();
      if (!user) {
        setPurchaseStatus('Please sign in to make a purchase');
        return;
      }

      const { sessionId } = await callCreditsCheckoutSession({
        cents,
        successUrl: window.location.origin + '/credits',
        cancelUrl: window.location.origin + '/credits',
      });

      if (!sessionId) {
        setPurchaseStatus('Error creating checkout session: ${JSON.stringify(response.json())}');
        return;
      }

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        setPurchaseStatus(`Error redirecting to checkout: ${error.message}`);
      }
    } catch (error) {
      setPurchaseStatus('Error processing purchase');
      console.error('Purchase error:', error);
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handlePurchase(Math.round(100 * customDollars));
  };

  return (
    <div>
      <h1 className="title">Credits</h1>
      <p>Buy credits. NOTE: This is alpha software. Service is provided as-is. No guaranteed refund policy for credits. Contact jessica@morpheus.systems with questions.</p>
      {userValue ? (<p>Credits: {formatUD(userValue.creditsUD)}</p>) : (<p>Loading current credits...</p>)}
      <button className="round-button" onClick={() => handlePurchase(1000)}>Buy $10</button>
      <button className="round-button" onClick={() => handlePurchase(2000)}>Buy $20</button>
      <button className="round-button" onClick={() => handlePurchase(5000)}>Buy $50</button>
      <button className="round-button" onClick={() => handlePurchase(10000)}>Buy $100</button>
      <form onSubmit={handleSubmit}>
        <label htmlFor="cents">Custom amount (in dollars), between $10 and $200:</label>
        <input
          type="number"
          id="dollars"
          value={customDollars}
          min={10}
          max={200}
          onChange={(e) => setCustomDollars(parseInt(e.target.value, 10))}
        />
        <button className="round-button" type="submit">
          Buy {formatUD(1000 * 1000 * customDollars)}
        </button>
        {purchaseStatus && <p>{purchaseStatus}</p>}
      </form>
      {purchaseValues == null ? (<div>Loading purchases...</div>) :
       purchaseValues.length == 0 ? (<div>No purchases yet</div>) : (
        <div>
          <h2>Purchase History</h2>
          <table>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
            {purchaseValues.map((purchase, i) => (
              <tr key={i}>
                <td>{new Date(purchase.startEpochTime).toLocaleString()}</td>
                <td>{formatUD(10000 * purchase.amountCents)}</td>
                <td>{purchase.status}</td>
              </tr>
            ))}
          </table>
          {startAfter && <button onClick={fetchPurchases}>Load more</button>}
        </div>)}
    </div>
  );
};

export default Credits;
