import React, { useState } from 'react';
import './CollapsibleSplitPanel.css';

const ChevronIcon: React.FC<{ direction: 'left' | 'right' }> = ({ direction }) => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    className={`chevron-icon ${direction}`}
  >
    <path 
      d={direction === 'left' ? "M10 2L4 8l6 6" : "M6 2l6 6-6 6"} 
      stroke="currentColor" 
      strokeWidth="2" 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

interface CollapsibleSplitPanelProps {
    leftPanel: React.ReactNode;
    rightPanel: React.ReactNode;
    defaultCollapsed?: boolean;
    /** Width of the left panel when expanded (CSS value) */
    leftPanelWidth?: string;
    /** Minimum width of left panel when expanded (CSS value) */
    minLeftWidth?: string;
    /** Width of left panel when collapsed (CSS value) */
    collapsedWidth?: string;
    /** Optional class name for additional styling */
    className?: string;
    /** Optional style overrides */
    style?: React.CSSProperties;
  }

  const CollapsibleSplitPanel: React.FC<CollapsibleSplitPanelProps> = ({ 
    leftPanel, 
    rightPanel, 
    defaultCollapsed = false,
    leftPanelWidth = "50%",
    minLeftWidth = "250px",
    collapsedWidth = "48px",
    className = '',
    style = {}
  }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultCollapsed);
    const [isHovering, setIsHovering] = useState<boolean>(false);
  
    const handleToggleCollapse = (): void => {
      setIsCollapsed(!isCollapsed);
    };
  
    return (
      <div 
        className={`collapsible-split-panel ${className}`.trim()}
        style={style}
      >
        <div 
          className="panel-left"
          style={{ 
            width: isCollapsed ? collapsedWidth : leftPanelWidth,
            minWidth: isCollapsed ? collapsedWidth : minLeftWidth
          }}
        >
          <div className={`panel-left-content ${isCollapsed ? 'collapsed' : ''}`}>
            {leftPanel}
          </div>
        </div>
  
        <div
          className="panel-divider"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={handleToggleCollapse}
        >
          <div className={`collapse-handle ${isCollapsed ? 'collapsed' : ''} ${isHovering ? 'visible' : ''}`}>
            <ChevronIcon direction={isCollapsed ? 'right' : 'left'} />
          </div>
        </div>
  
        <div className="panel-right">
          {rightPanel}
        </div>
      </div>
    );
  };

export default CollapsibleSplitPanel;