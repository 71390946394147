import React, { useState } from 'react';
import { FireLoomStore } from './db';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';

let globalStore: FireLoomStore | null = null;

export function useFireLoomStore() {
  const [store] = useState(() => {
    if (!globalStore) {
      globalStore = new FireLoomStore();
    }
    return globalStore;
  });

  return store;
}

export const useAppDispatch = () => useDispatch<AppDispatch>();