import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import transcriptViewerReducer from './pages/transcriptViewerSlice';
import annotationPanelReducer from './components/transcriptSidePanel/annotationPanelSlice';
import queryPanelReducer from './components/transcriptSidePanel/queryPanelSlice';

export const store = configureStore({
  reducer: {
    transcriptViewer: transcriptViewerReducer,
    annotationPanel: annotationPanelReducer,
    queryPanel: queryPanelReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;