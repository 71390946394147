import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './PromptTemplateManager.css';
import { PromptTemplate, Conversation } from '../common/common_db';
import PromptTemplateForm from '../components/PromptTemplateForm';
import { FireLoomStore } from '../db';
import { doc } from 'firebase/firestore';

const PromptTemplateManager = () => {
  const { templateID } = useParams<{templateID: string}>(); // Get templateID from URL if present
  const [docStore, setDocStore] = useState(new FireLoomStore());
  const [templates, setTemplates] = useState<PromptTemplate[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate | null>(null);
  const [mode, setMode] = useState<'preview' | 'edit'>('preview');
  const [editText, setEditText] = useState('');
  const [editName, setEditName] = useState('');
  const [editTemplateVisibility, setEditTemplateVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch templates and conversations on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const templates = await docStore.getTemplates(false)
        const conversations = await docStore.getConversations();
        
        setTemplates(templates);
        setConversations(conversations);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    };

    fetchData();
  }, []);

  // Handle URL-based template selection
  useEffect(() => {
    if (templateID && templates.length > 0) {
      const template = templates.find(t => t.id === templateID);
      if (template) {
        setSelectedTemplate(template);
        setEditText(template.templateText);
        setEditName(template.name);
        setEditTemplateVisibility(template.isPublic);
      }
    }
  }, [templateID, templates]);

  const handleSaveTemplate = async () => {
    if (!selectedTemplate) return;

    try {
      const updatedTemplate = {
        ...selectedTemplate, 
        templateText: editText, 
        name: editName, 
        isPublic: editTemplateVisibility};
      docStore.updateTemplate(updatedTemplate.id, updatedTemplate);
      // Update local state
      setTemplates(templates.map(t => 
        t.id === selectedTemplate.id 
          ? updatedTemplate
          : t
      ));

      setMode('preview');
    } catch (error) {
      console.error('Error saving template:', error);
      // TODO: Add error handling UI
    }
  };

  const handleCreateTemplate = async () => {
    try {
      const template = await docStore.createTemplate("New Template")
      console.log("newly created template: ", template)
      setTemplates([...templates, template]);
      navigate(`/templates/${template.id}`);
      setMode('edit');
    } catch (error) {
      console.error('Error creating template:', error);
      // TODO: Add error handling UI
    }
  };

  const handleDeleteTemplate = async () => {
    if (!window.confirm('Are you sure you want to delete this template?')) return;
    await docStore.deleteTemplate(selectedTemplate!.id);
    setSelectedTemplate(null);
    setTemplates((templates) => templates.filter(t => t.id !== selectedTemplate!.id));
  }

  if (loading) {
    return <div>Loading...</div>; // TODO: Add proper loading UI
  }

  return (
    <div className="template-manager">
      <div className="template-list">
        <div className="list-header">
          <h2>Your Templates</h2>
          <button 
            className="new-template-button"
            onClick={handleCreateTemplate}
          >
            New Template
          </button>
        </div>
        
        <div className="templates">
          {templates.map(template => (
            <div 
              key={template.id}
              className={`template-item ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
              onClick={()=> navigate(`/templates/${template.id}`)}
            >
              <h3>{template.name}</h3>
              <p className="template-preview">
                {template.templateText.slice(0, 100)}
                {template.templateText.length > 100 ? '...' : ''}
              </p>
            </div>
          ))}
        </div>
      </div>

      {selectedTemplate && (
        <div className="template-sidebar">
          <div className="sidebar-header">
          <div className="template-header-content">
          {mode === 'edit' ? (
            <input
              type="text"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              className="template-name-input"
            />
          ) : (
            <h2 className="template-name">{selectedTemplate.name}</h2>
          )}
          
          <button 
            className={`visibility-toggle ${editTemplateVisibility ? 'public' : 'private'}`}
            onClick={() => {editTemplateVisibility ? setEditTemplateVisibility(false) : setEditTemplateVisibility(true)}}
            disabled={mode === 'preview'}
          >
            {editTemplateVisibility ? 'Public' : 'Private'}
          </button>
        </div>
            <div className="tab-buttons">
              <button 
                className={`tab-button ${mode === 'preview' ? 'active' : ''}`}
                onClick={() => setMode('preview')}
              >
                Preview
              </button>
              <button 
                className={`tab-button ${mode === 'edit' ? 'active' : ''}`}
                onClick={() => setMode('edit')}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="sidebar-content">
            {mode === 'preview' ? (
              <div className="preview-mode">
                <PromptTemplateForm
                  template={selectedTemplate}
                  conversations={conversations}
                  hideSubmit
                  onSubmit={() => {}}
                />
              </div>
            ) : (
              <div className="edit-mode">
                <p>
                  {'Template keywords: type '}
                  <span className='template-keyword'>{'${conversation}'}</span>{' to insert a transcript of selected convo. For general free-form text, type '}
                  <span className='template-keyword'>{'${name-of-your-input}'}</span> {'. Slots that have the same name will be templated with the same text.'}</p>
                <textarea
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  placeholder="Enter your template text..."
                  className="template-editor"
                />
                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <button 
                    className="save-button"
                    onClick={handleSaveTemplate}
                  >
                    Save Changes
                  </button>
                  <button className='delete-button' onClick={handleDeleteTemplate}>
                    Delete Template
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptTemplateManager;